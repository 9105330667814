export function stripNonNumerics(value: string): string | null {
	value = value.replace(/[^\d.]/g, "");
	const isOnlyZeros = new RegExp(/^00+/).test(value);
	const isMultiplePoints = new RegExp(/(\..*){2,}/).test(value);
	const isFirstZero = new RegExp(/^0[1-9]+/).test(value);

	if (isMultiplePoints) return null;

	if (value.length === 0 || isOnlyZeros || isFirstZero) {
		return "";
	} else if (value[0] === ".") {
		return "0.";
	} else {
		return value;
	}
}
