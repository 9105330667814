import BigNumber from "bignumber.js";

// ToDo: remove "bignumber.js" dependency

export const toFixed = (price: number | null | string) => {
	if (!price) return "0";

	return new BigNumber(new BigNumber(price).toPrecision(6)).toFixed();
};

export const toPrecision = (price: string, isFixed = true) => {
	if (!price) return price;

	if (isFixed) {
		return new BigNumber(toFixed(price)).toFixed(2);
	} else {
		return toFixed(price);
	}
};
