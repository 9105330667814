import cn from "classnames";
import { toFixed } from "../../helpers/toFixed";
import styles from "./MedianPrice.module.scss";

export interface MedianPriceProps {
	leftName: string;
	rightName: string;
	price?: number | string | null;
}

export const MedianPrice = ({ leftName, rightName, price }: MedianPriceProps) => {
	// ToDo: redesign median price container when it's empty
	return (
		<div className={styles.wrapper}>
			<p className={cn(styles.price)}>
				{price ? (
					<>
						<span>1</span> <span data-testid="token-from-ticker">{leftName}</span> ={" "}
						<span data-testid="token-rate-to">{toFixed(price)}</span>{" "}
						<span data-testid="token-to-ticker">{rightName}</span>
					</>
				) : null}
			</p>
		</div>
	);
};
