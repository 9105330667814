import { useEthers } from "@usedapp/core";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as Cross } from "../../assets/images/icons/close.svg";
import { ReactComponent as Gear } from "../../assets/images/icons/settings.svg";
import Card from "../../components/Card";
import Pairs from "../../components/Pairs";
import Swap from "../../components/Swap";
import { useEcosystem } from "../../contexts/EcosystemContext/useEcosystem";
import { useRateCalculator } from "../../hooks/useRateCalculator";
import { useToggle } from "../../hooks/useToggle";
import { IPairToken } from "../../types";
import styles from "./SelectorCard.module.scss";

type SelectorCardProps = {
	slippage: string;
	onSettingsToggle: () => void;
};

const SelectorCard = ({ slippage, onSettingsToggle }: SelectorCardProps) => {
	const [swapDirection, setSwapDirection] = useState<"buy" | "sell">("buy");
	const [selectedPair, setSelectedPair] = useState<IPairToken | null>(null);

	const { account } = useEthers();

	const { isLoading, ecosystemTokenPairs, ecosystem } = useEcosystem();

	const memoizedEcosystemTokenPairs = useMemo(() => ecosystemTokenPairs, [ecosystem, isLoading]);

	useEffect(() => {
		const mainPair = ecosystemTokenPairs?.[0] ?? null;

		setSelectedPair(mainPair);
		setSwapDirection("buy");
	}, [memoizedEcosystemTokenPairs]);

	const { activateBrowserWallet } = useEthers();
	const {
		fromAmount,
		toAmount,
		setFromAmount,
		setToAmount,
		clearAmounts,
		rotateAmounts,
		medianPrice,
		isLoading: isRateLoading,
	} = useRateCalculator(selectedPair, swapDirection);
	const [isVisiblePairs, onVisiblePairs] = useToggle(false);

	if (isLoading) return <></>;

	return (
		<Card style={{ width: "560px", height: "600px" }}>
			<Card.Header subtitle="Select pair">
				{/* TMP - gonna make something with it */}
				{isVisiblePairs ? (
					<Cross onClick={() => onVisiblePairs()} className={styles.headerIcon} />
				) : (
					<Gear
						onClick={() => onSettingsToggle()}
						data-testid="toggle-settings-button"
						className={styles.headerIcon}
					/>
				)}
			</Card.Header>
			<Card.Body style={{ overflow: "hidden", position: "relative" }}>
				{/* TMP - gonna make something with it */}
				{isVisiblePairs && (
					<Pairs
						selectedPair={selectedPair}
						swapDirection={swapDirection}
						ecosystemTokenPairs={memoizedEcosystemTokenPairs}
						onSelectedPairChange={setSelectedPair}
						onSwapDirectionChange={setSwapDirection}
						onVisiblePairs={onVisiblePairs}
						setFromAmount={setFromAmount}
						setToAmount={setToAmount}
					/>
				)}

				<Swap
					account={account}
					selectedPair={selectedPair}
					swapDirection={swapDirection}
					slippage={slippage}
					onConnectWalletClick={activateBrowserWallet}
					onSwapDirectionChange={setSwapDirection}
					onSelectedPairChange={setSelectedPair}
					onVisiblePairs={onVisiblePairs}
					fromAmount={fromAmount}
					toAmount={toAmount}
					setFromAmount={setFromAmount}
					setToAmount={setToAmount}
					clearAmounts={clearAmounts}
					rotateAmounts={rotateAmounts}
					isRateLoading={isRateLoading}
					medianPrice={medianPrice}
				/>
			</Card.Body>
		</Card>
	);
};

export default SelectorCard;
