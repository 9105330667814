import cn from "classnames";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/Card";
import { Field, IconField } from "../../components/Shared";
import { resources } from "../../constants/resources";
import { useEcosystem } from "../../contexts/EcosystemContext";
import saveBlobToIpfs from "../../helpers/saveBlobToIpfs";
import { useCreateToken } from "../../wrappers/tokenFactory";
import styles from "./CreateForm.module.scss";
import CreateTokenSchema from "./CreateTokenSchema";
import { CollateralToken } from "./InfoCard";

interface CreateTokenFormProps {
	dataCollateralToken?: CollateralToken[];
	setDisabledInfoPage?: (x: any) => void;
}

interface CreateTokenForm {
	name: string;
	symbol: string;
	icon: File | null;
	decimals: string;
}

export const DEFAULT_VALUES: CreateTokenForm = {
	name: "",
	symbol: "",
	icon: null,
	decimals: "16",
};

const TokenForm = ({ dataCollateralToken, setDisabledInfoPage }: CreateTokenFormProps) => {
	const [isPendingTx, setPendingTx] = useState(false);
	const { network, ecosystemTokens } = useEcosystem();
	const { createToken, status: tokenCreatingTx } = useCreateToken();
	const [isModalCard, setModalCard] = useState(false);
	const [valuesProps, setValuesProps] = useState(DEFAULT_VALUES);

	useEffect(() => {
		if (tokenCreatingTx.status === "Mining" || tokenCreatingTx.status === "PendingSignature") {
			setPendingTx(true);
		} else {
			setPendingTx(false);
		}
	}, [tokenCreatingTx]);

	const handleSubmit = async (values: CreateTokenForm) => {
		const { symbol, name, icon } = values;

		try {
			if (!icon) return;
			setPendingTx(true);
			setDisabledInfoPage && setDisabledInfoPage(true);
			const iconUrl = await saveBlobToIpfs(icon); // ToDo: move to hook?

			if (dataCollateralToken && dataCollateralToken?.length > 0) {
				const addr = dataCollateralToken.map((x) => x.address);

				const referenceUrl = dataCollateralToken.map((x) => x.logoURI);
				const tokenName = dataCollateralToken.map((x) => x.name);

				const newAssetsImg = resources.filter(
					(x) => x.title.toLowerCase() === tokenName[0]!.toLowerCase(),
				);

				const createBlob =
					newAssetsImg.length > 0 && !referenceUrl[0]
						? await (await fetch(newAssetsImg[0].icon)).blob()
						: null;
				const createAssetsImg = createBlob ? await saveBlobToIpfs(createBlob) : null;

				await createToken(symbol, name, iconUrl, undefined, undefined, [
					{
						addr: addr[0],
						referenceUrl: referenceUrl[0] ? referenceUrl[0] : createAssetsImg || "",
					},
				]);

				// ToDo: autoselect created token in the pairs dropdown
			} else {
				await createToken(symbol, name, iconUrl);
			}
		} catch (_: any) {
			// TBD
		} finally {
			setPendingTx(false);
			setDisabledInfoPage && setDisabledInfoPage(false);
		}
	};

	if (tokenCreatingTx?.status === "Success") {
		return <Navigate to="../exchange" replace />;
	}
	const disagreeCreateCollateralToken = (x: boolean) => {
		return () => {
			setModalCard(x);
			setDisabledInfoPage && setDisabledInfoPage(x);
		};
	};
	const agreeCreateCollateralToken = (x: any) => {
		return () => {
			handleSubmit(x);
		};
	};
	return (
		<>
			{!isModalCard ? (
				<Formik
					initialValues={DEFAULT_VALUES}
					onSubmit={(values) => {
						if (dataCollateralToken && dataCollateralToken?.length > 0) {
							setModalCard && setModalCard(true);
							setValuesProps(values);
							setDisabledInfoPage && setDisabledInfoPage(true);
						} else {
							handleSubmit(values);
						}
					}}
					validationSchema={CreateTokenSchema}
				>
					{({ errors, touched, values }) => {
						const areErrors = Object.values(errors).length > 0;
						const isTouched = Object.values(touched).length > 0;
						const isDisabled = !isTouched || areErrors;

						return (
							<Form className={styles.form}>
								<div className={styles.fieldGroup}>
									<Field
										disabled={isPendingTx}
										data-testid="create-token-name-field"
										label="Token Name"
										name="name"
										maxLength={16}
										invalid={Boolean(errors.name) && touched.name}
										value={values.name
											// .replace(/[^a-z0-9\s]/gi, "")
											.replace(/\s+/gi, " ")
											.trimStart()}
									/>

									{errors.name && touched.name && (
										<div className={styles.errorMessage}>{errors.name}</div>
									)}
								</div>

								<div
									data-testid="field-group-double-input"
									className={styles.fieldGroupDoubleInput}
								>
									<>
										<Field
											disabled={isPendingTx}
											data-testid="create-token-symbol-field"
											label="Token ID"
											name="symbol"
											minLength={3}
											maxLength={4}
											invalid={Boolean(errors.symbol) && touched.symbol}
											value={values.symbol.trim()}
										/>
										{errors.symbol && touched.symbol && (
											<div
												style={{ right: "52%" }}
												data-testid="create-token-symbol-field-error-message"
												className={styles.errorMessage}
											>
												{errors.symbol}
											</div>
										)}
									</>

									<Field value={values.decimals} label="Decimals" name="decimals" disabled />
								</div>

								<div className={cn(styles.fieldGroup, styles.iconGroup)}>
									<IconField disabled={isPendingTx} label="Upload your icon" />
								</div>

								<div className={styles.controls} style={{ marginTop: "auto" }}>
									<Button
										data-testid="create-token-create-button"
										type="submit"
										lg
										primary
										disabled={isDisabled || isPendingTx}
									>
										Create token
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			) : (
				<Card.Modal
					ecosystemToken={ecosystemTokens.BONDED_TOKEN.label}
					tokenName={valuesProps.name}
					tokenSymbol={valuesProps.symbol}
					collateralToken={dataCollateralToken && dataCollateralToken[0]?.name}
					network={network}
				>
					<div className={styles.modalButtonBlock}>
						<Button
							className={styles.modalButtonCreate}
							onClick={agreeCreateCollateralToken(valuesProps)}
							lg
							primary
							disabled={isPendingTx}
						>
							Ok
						</Button>
						<Button
							disabled={isPendingTx}
							className={styles.modalButtonCancel}
							onClick={disagreeCreateCollateralToken(false)}
							lg
						>
							CANCEL
						</Button>
					</div>
				</Card.Modal>
			)}
		</>
	);
};

export default TokenForm;
