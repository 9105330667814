import { HTMLAttributes, ReactElement } from "react";
import AwayListener from "react-click-away-listener";

declare type MouseEvents = "click" | "mousedown" | "mouseup";
declare type TouchEvents = "touchstart" | "touchend";

interface Props extends HTMLAttributes<HTMLElement> {
	onClickAway: () => void;
	mouseEvent?: MouseEvents;
	touchEvent?: TouchEvents;
	children: ReactElement<any>;
}

export const ClickAwayListener = (props: Props) => {
	const { children, onClickAway, mouseEvent = "mousedown", touchEvent = "touchend" } = props;

	return (
		<AwayListener onClickAway={onClickAway} mouseEvent={mouseEvent} touchEvent={touchEvent}>
			{children}
		</AwayListener>
	);
};
