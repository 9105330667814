import cn from "classnames";
import { forwardRef } from "react";
import styles from "./NetworkPopup.module.scss";
import SelectNetwork from "./SelectNetwork";

interface NetworkPopupProps {
	isOpen: boolean;
}

const NetworkPopup = forwardRef<HTMLDivElement, NetworkPopupProps>(({ isOpen }, ref) => {
	// const { account } = useEthers();

	const isInjected = false; //!!account; // ToDo: fix it when new connectors will be added

	return (
		<div ref={ref} className={styles.root}>
			{isInjected ? (
				<p className={cn(styles.popup, styles.provider, { [styles.providerOpen]: isOpen })}>
					Change network or Ethereum provider in MetaMask wallet or WalletConnect. All RPC requests
					are forwarded via MetaMask.
				</p>
			) : (
				<SelectNetwork
					className={cn(styles.popup, styles.provider, { [styles.providerOpen]: isOpen })}
				/>
			)}
		</div>
	);
});

NetworkPopup.displayName = "NetworkPopup";

export { NetworkPopup };
