import cn from "classnames";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { useState } from "react";
import { DEFAULT_SLIPPAGES } from "../../constants";
import styles from "./Exchange.module.scss";
import SelectorCard from "./SelectorCard";
import SettingsCard from "./SettingsCard";

const Exchange = () => {
	const [areSettingsToggled, toggleSettings] = useCycle(false, true);
	const [slippage, setSlippage] = useState(DEFAULT_SLIPPAGES[0]);

	return (
		<>
			<AnimatePresence>
				{areSettingsToggled && (
					<motion.div
						key="child"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
						className={cn(styles.cardContainer, styles.upperLayered)}
					>
						<SettingsCard
							slippage={slippage}
							onSlippageChange={setSlippage}
							onSettingsToggle={toggleSettings}
						></SettingsCard>
					</motion.div>
				)}
			</AnimatePresence>

			<div className={styles.cardContainer}>
				<SelectorCard slippage={slippage} onSettingsToggle={toggleSettings}></SelectorCard>
			</div>
		</>
	);
};

export default Exchange;
