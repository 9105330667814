import cn from "classnames";
import { FC } from "react";
import { ReactComponent as AlertOctagon } from "../../assets/images/icons/alert-octagon.svg";
import { ReactComponent as Chevron } from "../../assets/images/icons/chevrons.svg";
import styles from "./PriceDrop.module.scss";

export interface PriceDropProps {
	percentDrop: number;
}

export const PriceDrop: FC<PriceDropProps> = ({ percentDrop }: PriceDropProps) => {
	return (
		<div className={cn(styles.wrapper)}>
			{Number(percentDrop.toFixed(2)) === 0 ? (
				<span className={styles.text}>No overall price impact</span>
			) : (
				<PriceDropPercent percent={percentDrop} />
			)}
		</div>
	);
};

interface PriceDropPercentProps {
	percent: number;
}

const PriceDropPercent: FC<PriceDropPercentProps> = ({ percent = 0 }) => {
	const isDrop = percent < 0 ? true : false;

	return (
		<div
			className={cn(styles.procent, {
				[styles.isDrop]: isDrop,
			})}
		>
			<AlertOctagon />
			<span className={cn(styles.text, styles.dropText)}>Price impact warning</span>
			<span className={styles.amountDrop}>
				<Chevron /> {percent.toFixed(2)}%
			</span>
		</div>
	);
};

export const MaxTokenSupplyReached: FC<{}> = () => {
	return (
		<div className={cn(styles.wrapper)}>
			<div
				className={cn(styles.procent, {
					[styles.isDrop]: true,
				})}
			>
				<AlertOctagon />
				<span className={cn(styles.text, styles.dropText)}>Maximum token supply reached</span>
			</div>
		</div>
	);
};
