import { useToken } from "@usedapp/core";
import { ethers, utils } from "ethers";
import { useEffect, useState } from "react";
import { ReactComponent as Arrows } from "../../assets/images/icons/arrows.svg";
import { slippageOf } from "../../helpers/slippageOf";
import { useAllowance } from "../../hooks/useAllowance";
import { useBalance } from "../../hooks/useBalance";
import { useCoingeckoTokenTotalPrice } from "../../hooks/useCoingeckoTokenPrice";
import { IPairToken } from "../../types";
import { useExchange } from "../../wrappers/bondingCurve";
import { useTokenApprove } from "../../wrappers/erc20Token";
import Button from "../Button";
import Input from "../Input";
import MedianPrice from "../MedianPrice";
import { MaxTokenSupplyReached } from "../PriceDrop/PriceDrop";
import SelectedPair from "../SelectedPair";
import StepProgressBar from "../StepProgressBar";
import SwapButtons from "../SwapButtons";
import styles from "./Swap.module.scss";

export interface SwapProps {
	account: string | null | undefined;
	selectedPair: IPairToken | null;
	swapDirection: "buy" | "sell";
	slippage: string;
	onSwapDirectionChange: (direction: "buy" | "sell") => void;
	onSelectedPairChange: (selectedPair: IPairToken) => void;
	onConnectWalletClick: () => void;
	onVisiblePairs: () => void;
	fromAmount: string;
	toAmount: string;
	medianPrice: number | undefined | null;
	setFromAmount: (x: any) => any;
	setToAmount: (x: any) => any;
	clearAmounts: () => void;
	rotateAmounts: () => void;
	isRateLoading: boolean;
}

export const Swap = (props: SwapProps) => {
	const {
		account,
		selectedPair,
		swapDirection,
		slippage,
		onSwapDirectionChange,
		onSelectedPairChange,
		onConnectWalletClick,
		onVisiblePairs,
		fromAmount,
		toAmount,
		medianPrice,
		setFromAmount,
		setToAmount,
		clearAmounts,
		rotateAmounts,
		isRateLoading,
	} = props;

	const [percent, setPercent] = useState<number>(0);
	const [isMaxTokenSupplyReached, setIsMaxTokenSupplyReached] = useState<boolean>(false);
	const [isBalanceUpdated, setBalanceUpdated] = useState(false); // ToDo: workaround to synchronize balance and write tx

	const fromTokenInfo = useToken(
		selectedPair?.sell.ABI !== "UNISWAP" ? selectedPair?.sell.address : undefined,
	);

	const fromBalance = useBalance(selectedPair?.sell);
	const toBalance = useBalance(selectedPair?.buy);

	const fromAllowance = useAllowance(selectedPair, swapDirection);
	const {
		approve: approveToken,
		status: approvingTx,
		resetState: resetApproveTx,
	} = useTokenApprove(selectedPair?.sell?.address); // ToDo: slippage

	const {
		exchange: exchangeTokens,
		resetState: resetExchangeTx,
		status: exchangeTx,
	} = useExchange(selectedPair, swapDirection, fromAmount, toAmount, slippage);

	const fromTokenAddress =
		selectedPair?.sell.ABI === "UNISWAP" ? "ethereum" : selectedPair?.sell.address;
	const toTokenAddress =
		selectedPair?.buy.ABI === "UNISWAP" ? "ethereum" : selectedPair?.buy.address;

	const fromUsdPrice = useCoingeckoTokenTotalPrice(fromTokenAddress, fromAmount);
	const toUsdPrice = useCoingeckoTokenTotalPrice(toTokenAddress, toAmount);

	function handleRotateSwapDirection() {
		if (selectedPair === null) return;

		const { _id, buy, sell } = selectedPair;
		const newValue: IPairToken = { _id, sell: buy, buy: sell };

		onSwapDirectionChange(swapDirection === "buy" ? "sell" : "buy");
		onSelectedPairChange(newValue);

		rotateAmounts();
	}

	// ToDo: move business logic to a hook
	const parsedFromAmount =
		selectedPair && fromAmount
			? ethers.utils.parseUnits(fromAmount, selectedPair?.sell.formatUnits)
			: null;
	const fromAmountWithSlippage = parsedFromAmount
		? parsedFromAmount.add(slippageOf(parsedFromAmount, slippage))
		: null;

	const isTokenApproved =
		(fromAllowance && fromAmountWithSlippage
			? fromAllowance?.gte(fromAmountWithSlippage)
			: false) || approvingTx.status === "Success";
	const isConnectNeeded = !account || account.length <= 0;
	const isApproveNeeded =
		!isConnectNeeded && !isTokenApproved && selectedPair?.sell.label !== "ETH";
	const isExchangeNeeded = !isConnectNeeded && !isApproveNeeded;

	const isDisabledFrom = selectedPair?.sell?.ABI === "UNISWAP";
	const isDisabledTo = selectedPair?.buy?.ABI === "UNISWAP";

	// ToDo: use bignumber
	// ToDo: slippage included?
	const isInsufficientBalance =
		fromBalance !== null && fromAmount !== null ? Number(fromBalance) < Number(fromAmount) : false;
	const isDisabled =
		isInsufficientBalance ||
		isMaxTokenSupplyReached ||
		!fromAmount ||
		Number(fromAmount) == 0 ||
		!toAmount ||
		Number(toAmount) == 0 ||
		isRateLoading;

	const isApproveLoading =
		approvingTx?.status === "PendingSignature" || approvingTx?.status === "Mining";

	const isExchnageLoading =
		exchangeTx?.status === "PendingSignature" ||
		exchangeTx?.status === "Mining" ||
		(exchangeTx?.status === "Success" && !isBalanceUpdated);

	useEffect(() => {
		if (isRateLoading) return;

		if (isConnectNeeded || isDisabled) {
			setPercent(0);
		} else if (isApproveNeeded) {
			setPercent(50);
		} else if (isExchangeNeeded) {
			setPercent(100);
		}
	}, [isConnectNeeded, isDisabled, isApproveNeeded, isExchangeNeeded, isRateLoading]);

	function handleApproveClick() {
		// ToDo: move it into business logic hook
		const { sell, buy } = selectedPair ?? {};

		if (!sell || !buy) return;

		// ToDo: move it into separate function because this logic is needed in several places
		// ToDo: sell Ethereum for Bonded token is ignored
		const isEthBroker = sell?.ABI === "UNISWAP" || buy?.ABI === "UNISWAP";
		const bondingCurve = swapDirection === "buy" ? buy?.curveAddress : sell?.curveAddress;
		const spenderAddress = !isEthBroker
			? bondingCurve
			: buy?.ABI === "UNISWAP"
			? buy?.curveAddress
			: null;

		// Sell Ethereum, buy token
		if (!spenderAddress) return;

		if (!fromAmountWithSlippage) return;

		approveToken(spenderAddress, fromAmountWithSlippage);
	}

	function handleExchangeClick() {
		// ToDo: move to business logic hook
		// ToDo: implement it
		exchangeTokens();
	}

	// ToDo: Move to exchange hook?
	function handleFromMaxClick() {
		if (!selectedPair || !fromBalance || fromBalance === "0") return;
		setFromAmount(fromBalance);
	}

	useEffect(() => {
		setBalanceUpdated(false);
		resetApproveTx();
		resetExchangeTx();
	}, [selectedPair, swapDirection, slippage, fromAmount, toAmount]);

	useEffect(() => {
		setBalanceUpdated(true);
	}, [fromBalance, toBalance]);

	useEffect(() => {
		if (exchangeTx.status === "Success" && isBalanceUpdated) {
			clearAmounts();
		}
	}, [exchangeTx, isBalanceUpdated]);

	useEffect(() => {
		// FROM is a native token (e.g. ETH)
		if (!fromTokenInfo?.totalSupply || !selectedPair || !fromAmount) {
			setIsMaxTokenSupplyReached(false);
		} else {
			const { formatUnits } = selectedPair.sell;
			const fromAmountBn = utils.parseUnits(fromAmount, formatUnits);

			if (fromAmountBn.gte(fromTokenInfo.totalSupply)) {
				setIsMaxTokenSupplyReached(true);
			} else {
				setIsMaxTokenSupplyReached(false);
			}
		}
	}, [fromTokenInfo, fromAmount, setIsMaxTokenSupplyReached, selectedPair]);

	return (
		<>
			<SelectedPair
				data-testid=""
				swapDirection={swapDirection}
				pair={selectedPair}
				onClick={onVisiblePairs}
			/>

			{selectedPair ? (
				<div className={styles.medianPrice}>
					<MedianPrice
						leftName={selectedPair.buy.label}
						rightName={selectedPair.sell.label}
						price={medianPrice}
					/>
				</div>
			) : null}

			<Input
				behavior="From"
				isMax={!!account}
				name="send"
				disabled={isDisabledFrom}
				token={selectedPair?.sell}
				minLength={1}
				maxLength={18}
				autoComplete="off"
				autoCorrect="off"
				data-testid="from-amount-input"
				usdPrice={fromUsdPrice}
				balance={fromBalance}
				amount={fromAmount}
				onChangeAmount={setFromAmount}
				onMaxButtonClick={handleFromMaxClick}
			/>

			<Button
				round
				className={styles.arrow}
				onClick={handleRotateSwapDirection}
				data-testid="swap-tokens-button"
			>
				<Arrows />
			</Button>

			<Input
				behavior="To (estimated)"
				name="receive"
				disabled={isDisabledTo}
				token={selectedPair?.buy}
				minLength={1}
				maxLength={18}
				autoComplete="off"
				autoCorrect="off"
				data-testid="to-amount-input"
				usdPrice={toUsdPrice}
				balance={toBalance}
				amount={toAmount}
				onChangeAmount={setToAmount}
			/>

			{/* <PriceDrop percentDrop={priceDrop} className={styles.priceDrop} /> */}

			{isMaxTokenSupplyReached ? <MaxTokenSupplyReached /> : null}

			<div className={styles.buttons} data-testid="swap-buttons-container">
				<SwapButtons
					approve={isApproveNeeded}
					connect={isConnectNeeded}
					isApproveLoading={isApproveLoading}
					exchange={isExchangeNeeded}
					isExchangeLoading={isExchnageLoading}
					onApproveClick={handleApproveClick}
					onExchangeClick={handleExchangeClick}
					isDisabled={isDisabled}
					isInsufficientBalance={isInsufficientBalance || isMaxTokenSupplyReached}
					onConnectWalletClick={onConnectWalletClick}
					fromAmount={fromAmount}
					toAmount={toAmount}
				/>
			</div>

			<div className={styles.progressBar}>
				<StepProgressBar percent={percent} />
			</div>
		</>
	);
};
