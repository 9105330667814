import React from "react";
import { ReactComponent as Arrows } from "../../assets/images/icons/arrows.svg";
import { ReactComponent as DropdownArrow } from "../../assets/images/icons/dropdown-arrow.svg";
import { IPairToken } from "../../types";
import styles from "./SelectedPair.module.scss";

export interface SelectedPairProps {
	pair: IPairToken | null;
	swapDirection: "buy" | "sell";
	onClick?: () => void;
}

export const SelectedPair = (props: SelectedPairProps) => {
	const { pair, swapDirection, onClick } = props;

	return (
		<div className={styles.wrapper} onClick={onClick} data-testid="selected-pair-dropdown">
			<div className={styles.left}>
				<h4 className={styles.pair}>
					{pair ? (
						swapDirection === "buy" ? (
							<React.Fragment>
								{pair.buy.label}
								<Arrows className={styles.arrows} />
								{pair.sell.label}
							</React.Fragment>
						) : (
							<React.Fragment>
								{pair.sell.label}
								<Arrows className={styles.arrows} />
								{pair.buy.label}
							</React.Fragment>
						)
					) : null}
				</h4>
			</div>

			<div className={styles.right}>
				<DropdownArrow className={styles.arrow} />
			</div>
		</div>
	);
};
