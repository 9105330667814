import jazzicon from "@metamask/jazzicon";
import { useChainMeta } from "@usedapp/core";
import cn from "classnames";
import { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { PAGE_URLS } from "../../constants";
import { useProvider } from "../../helpers/useProvider";
import { ClickAwayListener } from "../../HOC/ClickAwayListener";
import { useClickAwayListener } from "../../hooks/useClickAwayListener";
import VanillaChildren from "../VanillaChildren";
import styles from "./WalletProfile.module.scss";

export interface WalletProfileProps {
	loggedIn: string;
	network: string;
	onDisconnect?: () => void;
}

export const WalletProfile = (props: WalletProfileProps): ReactElement => {
	const { loggedIn, network, onDisconnect } = props;

	const { isListening, onClickAway, open } = useClickAwayListener();
	const { chainId } = useProvider();
	const chain = useChainMeta(chainId ?? 1); // ToDo: use DEFAULT_CHAIN_ID

	const [isCopy, setIsCopy] = useState<boolean>(false);

	const { href } = window.location;
	const urlSegments = href.split("/");
	const currentPage = urlSegments[urlSegments.length - 1];

	const copyAddress = (address: string | null) => () => {
		if (isCopy || !address) return false;

		navigator.clipboard
			.writeText(address)
			.then(copyTimeout)
			.catch((err) => console.log("Something went wrong", err));
	};

	function copyTimeout() {
		setIsCopy(true);
		const timeout = setTimeout(() => {
			setIsCopy(false);
			clearTimeout(timeout);
		}, 1000);
	}

	const getAvatar = (hash: string): HTMLDivElement => jazzicon(40, parseInt(hash.slice(2, 10), 16));
	const transformLoggerIn = (hash: string): string => `${hash.slice(0, 6)}...${hash.slice(-4)}`;

	const shouldAddGoToExchangeLink = currentPage === PAGE_URLS.CREATE_TOKEN;
	const shouldAddGoToCreateTokenLink = currentPage === PAGE_URLS.EXCHANGE;

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<div className={styles.wrapper}>
				<div className={styles.top} onClick={open} data-testid="account-details-button">
					<span data-testid="connected-account-label" className={styles.loggedIn}>
						{transformLoggerIn(loggedIn)}
					</span>
					<span className={styles.icon}>
						<VanillaChildren>{getAvatar(loggedIn)}</VanillaChildren>
					</span>
				</div>

				<ul
					className={cn(styles.list, "transition", {
						[styles.isShow]: isListening,
					})}
				>
					<li data-testid="copy-button" className={styles.listItem} onClick={copyAddress(loggedIn)}>
						{isCopy ? "Copied" : "Copy Address"}
					</li>

					<li className={styles.listItem}>
						<a
							data-testid="tx-history-button"
							target="_blank"
							rel="noopener noreferrer"
							className={styles.listItem}
							href={chain.getExplorerAddressLink(loggedIn)}
						>
							Tx History
						</a>
					</li>

					{shouldAddGoToExchangeLink && (
						<li className={styles.listItem} onClick={onClickAway}>
							<Link
								data-testid="create-token-button"
								rel="noopener noreferrer"
								className={styles.listItem}
								to="exchange"
							>
								Exchange
							</Link>
						</li>
					)}

					{shouldAddGoToCreateTokenLink && (
						<li className={styles.listItem} onClick={onClickAway}>
							<Link data-testid="create-token-button" rel="noopener noreferrer" to="create-token">
								Create Token
							</Link>
						</li>
					)}

					<li
						className={styles.listItem}
						onClick={onDisconnect}
						data-testid="disconnect-wallet-button"
					>
						Disconnect
					</li>
				</ul>
			</div>
		</ClickAwayListener>
	);
};
