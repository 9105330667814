import classNames from "classnames";
import { useEffect, useState } from "react";
import FORMULA from "../../assets/images/formula.png";
import { ReactComponent as Copy } from "../../assets/images/icons/copy.svg";
import { ReactComponent as DeleteToken } from "../../assets/images/icons/delete_token.svg";
import { ReactComponent as Up } from "../../assets/images/icons/up.svg";
// import ETH from "../../assets/images/logo_tokens/ethereum.png";
import { useChainMeta } from "@usedapp/core";
import { ReactComponent as Down } from "../../assets/images/icons/down.svg";
import { resources } from "../../constants/resources";
import { useEcosystem } from "../../contexts/EcosystemContext";
import formatIconRefUrl from "../../helpers/formatIconRefUrl";
import { numberWithCommas } from "../../helpers/numberWithCommas";
import { useProvider } from "../../helpers/useProvider";
import { useCoingeckoCoinInfo } from "../../hooks/useCoingeckoCoinInfo";
import { CollateralToken } from "../../pages/CreateToken/InfoCard";
import Button from "../Button";
import styles from "./InfoPages.module.scss";
import { InfoToken } from "./InfoToken";

export type YourCollateralTokenProps = {
	isLearnMore: boolean;
	setPage: (x: any) => void;
	setVisibleDropdown: (x: any) => void;
	collateralToken: any;
	setLearnMore: (x: any) => void;
	isVisibleDelete: boolean;
	setVisibleDelete: (x: any) => void;
	copyAddress: (x: any) => void;
	setDataCollateralToken: (x: any) => void;
	isDisabledInfoPage: boolean;
	setChooseToken: (x: any) => void;
};

export const YourCollateralToken = ({
	isLearnMore,
	setPage,
	setVisibleDropdown,
	setLearnMore,
	collateralToken,
	isVisibleDelete,
	setVisibleDelete,
	copyAddress,
	setDataCollateralToken,
	isDisabledInfoPage,
	setChooseToken,
}: YourCollateralTokenProps) => {
	const { network } = useEcosystem();
	const wethPrice = useCoingeckoCoinInfo(collateralToken.address, "usd");
	const { chainId } = useProvider();
	const chain = useChainMeta(chainId ?? 1); // ToDo: use DEFAULT_CHAIN_ID

	const newDataCollateralToken: CollateralToken[] = [];

	const getDeleteToken = (x: boolean, i: number) => {
		return () => {
			setChooseToken("");
			setVisibleDropdown(x);
			setPage(i);
			setVisibleDelete(x);
		};
	};

	const transitionEtherscan = (address: string) => {
		return (event: React.MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			window.open(chain.getExplorerAddressLink(address), "_blank");
		};
	};

	const getInfoPage = (x: boolean, i: number | null) => {
		return () => {
			if (i !== null) {
				setPage(i);
				setLearnMore(x);
			} else {
				setLearnMore(x);
			}
		};
	};

	const saveCollateralToken = (x: boolean, collateralToken?: any) => {
		return () => {
			if (x) {
				newDataCollateralToken.push(collateralToken);
				setDataCollateralToken(newDataCollateralToken);
				setVisibleDelete(x);
			} else {
				newDataCollateralToken.shift();
				setDataCollateralToken(newDataCollateralToken);
				setVisibleDelete(x);
			}
		};
	};

	const [isImg, setImg] = useState(false);
	const [urlImg, setUrlImg] = useState<string | null>(null);

	useEffect(() => {
		const init = async () => {
			refresh();
		};
		init();
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}, [urlImg]);

	const refresh = async () => {
		resources
			.filter((x) => x.title.toLowerCase() === collateralToken.name.toLowerCase())
			.map((x) => {
				return setUrlImg(x.icon);
			});

		if (!urlImg) {
			// fetch(collateralToken.logoURI)
			// 	.then((response) => response.blob())
			// 	.then((image) => {
			// 		setUrlImg(URL.createObjectURL(image));
			// 	})
			// 	.catch(() => {
			// 		setUrlImg(null);
			// 	});
			setUrlImg(collateralToken.logoURI);
		}
	};

	return (
		<>
			{!isLearnMore ? (
				<>
					<label
						className={classNames(styles.labelDropdownSearch, {
							[styles.LabelNoActive]: true,
						})}
					>
						<input
							spellCheck="false"
							className={styles.inputDropdownSearch}
							style={{ color: "#3F3F3F", fontSize: "16px", fontWeight: 700, paddingLeft: "50px" }}
							type="text"
							value={collateralToken.name}
							disabled
						/>
						{(collateralToken.logoURI && !isImg) || (urlImg && !isImg) ? (
							<img
								onError={() => setImg(true)}
								className={classNames(styles.tokenImg, {
									[styles.tokenImgInInput]: true,
								})}
								src={urlImg || formatIconRefUrl(collateralToken.logoURI)}
							></img>
						) : (
							<span
								className={classNames(styles.tokenNoImg, {
									[styles.tokenImgInInput]: true,
								})}
							>
								{collateralToken.symbol}
							</span>
						)}
						{isVisibleDelete ? null : <DeleteToken onClick={getDeleteToken(false, 3)} />}
					</label>
					<div className={styles.infoTokenBlock}>
						<div className={styles.infoToken}>
							<div className={styles.infoTokenLeft}>
								{(collateralToken.logoURI && !isImg) || (urlImg && !isImg) ? (
									<img
										onError={() => setImg(true)}
										className={styles.tokenImg}
										src={urlImg || formatIconRefUrl(collateralToken.logoURI)}
									></img>
								) : (
									<span className={styles.tokenNoImg}>{collateralToken.symbol}</span>
								)}
							</div>
							<div className={styles.infoTokenRight}>
								<div className={styles.tokenTitle}>{collateralToken.name}</div>
								<div className={styles.tokenLink}>
									<span
										onClick={transitionEtherscan(collateralToken.address)}
										className={styles.tokenAddress}
									>
										{collateralToken.address}
									</span>
									<span className={styles.tokenCopy} onClick={copyAddress}>
										<Copy />
									</span>
								</div>

								<span className={styles.tokenTitleSecond}>{collateralToken.symbol}</span>
							</div>
						</div>
						<div className={styles.infoDelimeter}></div>
						<div className={styles.tokenParameters}>
							{wethPrice && wethPrice.price ? (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>
										&#36;{numberWithCommas(wethPrice.price)}
									</span>
									<div className={styles.parameterGrowth}>
										{wethPrice.price_change && wethPrice.price_change < 0 ? <Down /> : <Up />}
										<span
											className={classNames(styles.parameterGrowthValue, {
												[styles.parameterGrowthValueLowZero]:
													wethPrice.price_change && wethPrice.price_change < 0,
											})}
										>
											{wethPrice.price_change && Math.abs(wethPrice.price_change)}&#37;
										</span>
									</div>
								</div>
							) : (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>n/a</span>
									<div className={styles.parameterGrowth}>
										<span className={styles.parameterGrowthValue}>price</span>
									</div>
								</div>
							)}
							{wethPrice && wethPrice.market_cap ? (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>
										&#36;{numberWithCommas(wethPrice.market_cap)}
									</span>
									<span className={styles.parameterDescription}>market cap</span>
								</div>
							) : (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>n/a</span>
									<span className={styles.parameterDescription}>market cap</span>
								</div>
							)}
							{wethPrice && wethPrice.trade_24h ? (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>
										&#36;{numberWithCommas(wethPrice.trade_24h)}
									</span>
									<span className={styles.parameterDescription}>24H trading vol</span>
								</div>
							) : (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>n/a</span>
									<span className={styles.parameterDescription}>24H trading vol</span>
								</div>
							)}
						</div>
					</div>
					<div className={styles.infoTokenText}>
						On this page you can create your own bonding curve supplied token with{" "}
						{collateralToken.symbol} as the collateral
					</div>
					<div className={styles.infoTokenText}>
						The formula for this curve is <img src={FORMULA}></img>{" "}
					</div>
					{!isLearnMore && (
						<Button onClick={getInfoPage(true, null)} link>
							learn more
						</Button>
					)}
					<div
						style={{
							display: "flex",
							marginTop: "auto",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						{!isVisibleDelete ? (
							<Button
								disabled={isDisabledInfoPage}
								primary
								data-testid="save-collateral-token"
								style={{ marginTop: "auto" }}
								onClick={saveCollateralToken(true, collateralToken)}
								className={styles.btnSaveNotDisabled}
							>
								SAVE
							</Button>
						) : (
							<Button
								data-testid="delete-collateral-token"
								onClick={saveCollateralToken(false)}
								disabled={isDisabledInfoPage}
								className={classNames(styles.btnSaveNotDisabled, styles.btnSaveNotDisabledDelete)}
							>
								DELETE
							</Button>
						)}

						<div className={styles.learnMore}>
							<Button onClick={getInfoPage(false, 0)} link>
								Go to the Main Page
							</Button>
						</div>
					</div>
				</>
			) : (
				<InfoToken label={collateralToken.symbol} decimals={collateralToken.formatUnits} />
			)}
		</>
	);
};
