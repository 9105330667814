import { useEffect } from "react";
import linkExternalCss from "../helpers/linkExternalCss";
import { relativelyUrl } from "../helpers/relativelyUrl";

interface ThemeConfig {
	styles: string;
	favicon: string;
}

export function useTheme(theme?: ThemeConfig) {
	useEffect(() => {
		if (!theme) return;

		linkExternalCss(relativelyUrl(theme.styles));
		const favicon = document.querySelector("link[rel=icon]");
		favicon?.setAttribute("href", relativelyUrl(theme.favicon));

		/* DOM operations take time, but async by default, we have to wait a little for them */
		/* TODO: make it nicer */
		// await delay(APPROX_CSS_LINK_TIME);
	}, [theme]);
}
