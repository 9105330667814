import { ReactNode } from "react";
import styles from "./Divider.module.scss";

type Props = { children?: ReactNode };

const Divider = ({ children }: Props) => {
	return (
		<div className={styles.root}>
			{children && <div className={styles.label}>{children}</div>}
			<div className={styles.hr}></div>
		</div>
	);
};

export default Divider;
