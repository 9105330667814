import { useNotifications } from "@usedapp/core";
import { FC } from "react";
import { Notification } from "../Notification/Notification";

export const Notifications: FC<{}> = () => {
	const { notifications } = useNotifications();

	return (
		<div style={{ position: "absolute", right: 15, left: "auto", top: 10 }}>
			{notifications.map((x) => {
				switch (x.type) {
					case "transactionStarted":
						return <Notification key={x.id} type={"loading"} hash={x.transaction.hash} />;
					case "transactionFailed":
						return <Notification key={x.id} type={"error"} hash={x.transaction.hash} />;
					case "transactionSucceed":
						return <Notification key={x.id} type={"success"} hash={x.transaction.hash} />;
					case "transactionRejected" as any:
						return <Notification key={x.id} type={"error"} message={(x as any)?.errorMessage} />;
					default:
						return null;
				}
			})}
		</div>
	);
};
