import { useChainMeta } from "@usedapp/core";
import classNames from "classnames";
import FORMULA from "../../assets/images/formula.png";
import { ReactComponent as Copy } from "../../assets/images/icons/copy.svg";
import { ReactComponent as Down } from "../../assets/images/icons/down.svg";
import { ReactComponent as Up } from "../../assets/images/icons/up.svg";
import { useEcosystem } from "../../contexts/EcosystemContext";
import { numberWithCommas } from "../../helpers/numberWithCommas";
import { useProvider } from "../../helpers/useProvider";
import { useCoingeckoCoinInfo } from "../../hooks/useCoingeckoCoinInfo";
import Button from "../Button";
import styles from "./InfoPages.module.scss";
import { InfoToken } from "./InfoToken";

export type CreateYourTokenPageElementProps = {
	label: string;
	setPage: (x: any) => void;
};

export const CreateYourTokenPageElement = ({ label, setPage }: CreateYourTokenPageElementProps) => {
	return (
		<>
			<div className={styles.infoItem} data-testid="page-info-create-token">
				You can create your token that will be using a bounding curve with {label} as a collateral
				for it. After creation process you will be routed to corresponding exchange page.
			</div>
			<hr className={styles.divider} />
			<div className={styles.infoItem}>To proceed please fill in the following fields:</div>
			<div className={styles.infoItem}>
				<span className={styles.bold}>Token Name</span>: <span>Give your token a name.</span>
			</div>

			<div className={styles.infoItem}>
				<span className={styles.bold}>Token ID (Ticker)</span>:{" "}
				<span>Unique identifier for your token.</span>
			</div>

			<div className={styles.infoItem}>
				<span className={styles.bold}>Icon URL</span>:{" "}
				<span>Please Upload token icon - png or svg file, 128x128 px</span>
			</div>

			<div className={styles.learnMore}>
				<Button data-testid="create-token-learn-more-button" onClick={setPage} link>
					Learn more about bonding curves and custom tokens
				</Button>
			</div>
		</>
	);
};

export type HowToCreateTokenElementProps = {
	setPage: (x: any) => void;
};

export const HowToCreateTokenElement = ({ setPage }: HowToCreateTokenElementProps) => {
	return (
		<>
			<div className={styles.infoItem}>
				Whatever purpose you need a token for, we can help you with it. For a token to be usable,
				there needs to be a clear way to mint new tokens and sell existing ones. This requires a
				market mechanism (someone has to sell tokens to the user at a fair price and exchange them
				back for another token when necessary). As a solution to this problem, the project proposes
				to use an automatic market maker (AMM) based on a Bonding Curve.
			</div>
			<div className={styles.infoItem}>
				A Bonding Curve is a smart contract that binds two tokens using a mathematical function that
				reflects the ratio of the token&apos;s price to its overall emission. Roughly speaking, the
				more tokens a user buys from the curve the higher the token&apos;s price will be. However,
				the curve allows you to make this ratio non-linear. Selling a token into the curve burns
				them off, freeing up liquidity and reducing the price of the next mint. To read more about
				Bonding Curves follow this link: TO_BE_LINK
			</div>
			<div className={styles.infoItem}>
				In order to launch your own token using the proposed model, the user just needs to go
				through a clear step-by-step process that includes selecting a name and a ticker (short
				form) for the token. The curve will be created and will be available right after it.
			</div>
			<div className={styles.learnMore}>
				<Button data-testid="create-token-got-it-button" onClick={setPage} link>
					Got it, send me back
				</Button>
			</div>
		</>
	);
};

export type InfoAboutEcosystemTokenProps = {
	setPage: (x: any) => void;
	setLearnMore: (x: any) => void;
	copyAddress: (x: any) => void;
	isLearnMore: boolean;
};

export const InfoAboutEcosystemToken = ({
	setPage,
	setLearnMore,
	isLearnMore,
	copyAddress,
}: InfoAboutEcosystemTokenProps) => {
	const { ecosystemTokens, network } = useEcosystem();
	const wethPrice = useCoingeckoCoinInfo(ecosystemTokens?.BONDED_TOKEN?.address, "usd");
	const { chainId } = useProvider();
	const chain = useChainMeta(chainId ?? 1); // ToDo: use DEFAULT_CHAIN_ID

	const transitionEtherscan = (address: string) => {
		return (event: React.MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			window.open(chain.getExplorerAddressLink(address), "_blank");
		};
	};

	const getInfoPage = (x: boolean, i?: number) => {
		return () => {
			if (x) {
				setLearnMore(x);
			} else {
				setPage(i);
				setLearnMore(x);
			}
		};
	};

	return (
		<>
			{!isLearnMore ? (
				<>
					<div className={styles.infoTokenBlock} data-testid="page-ecosystem-token">
						<div className={styles.infoToken}>
							<div className={styles.infoTokenLeft}>
								<img
									className={styles.tokenImg}
									src={`${ecosystemTokens?.BONDED_TOKEN?.icon}`}
								></img>
							</div>
							<div className={styles.infoTokenRight}>
								<div className={styles.tokenTitle}>{ecosystemTokens?.BONDED_TOKEN?.label}</div>
								<div className={styles.tokenLink}>
									<span
										onClick={transitionEtherscan(ecosystemTokens?.BONDED_TOKEN?.address)}
										className={styles.tokenAddress}
									>
										{ecosystemTokens?.BONDED_TOKEN?.address}
									</span>
									<span className={styles.tokenCopy} onClick={copyAddress}>
										<Copy />
									</span>
								</div>

								<span className={styles.tokenTitleSecond}>
									{ecosystemTokens?.BONDED_TOKEN?.label}
								</span>
							</div>
						</div>
						<div className={styles.infoDelimeter}></div>
						<div className={styles.tokenParameters}>
							{wethPrice && wethPrice.price ? (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>
										&#36;{numberWithCommas(wethPrice.price)}
									</span>
									<div className={styles.parameterGrowth}>
										{wethPrice.price_change && wethPrice.price_change < 0 ? <Down /> : <Up />}
										<span
											className={classNames(styles.parameterGrowthValue, {
												[styles.parameterGrowthValueLowZero]:
													wethPrice.price_change && wethPrice.price_change < 0,
											})}
										>
											{wethPrice.price_change && Math.abs(wethPrice.price_change)}&#37;
										</span>
									</div>
								</div>
							) : (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>n/a</span>
									<div className={styles.parameterGrowth}>
										<span className={styles.parameterGrowthValue}>price</span>
									</div>
								</div>
							)}
							{wethPrice && wethPrice.market_cap ? (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>
										&#36;{numberWithCommas(wethPrice.market_cap)}
									</span>
									<span className={styles.parameterDescription}>market cap</span>
								</div>
							) : (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>n/a</span>
									<span className={styles.parameterDescription}>market cap</span>
								</div>
							)}
							{wethPrice && wethPrice.trade_24h ? (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>
										&#36;{numberWithCommas(wethPrice.trade_24h)}
									</span>
									<span className={styles.parameterDescription}>24H trading vol</span>
								</div>
							) : (
								<div className={styles.parameterBlock}>
									<span className={styles.parameterValue}>n/a</span>
									<span className={styles.parameterDescription}>24H trading vol</span>
								</div>
							)}
						</div>
					</div>
					<div className={styles.infoTokenText}>
						On this page you can create your own bonding curve supplied token with{" "}
						{ecosystemTokens?.BONDED_TOKEN?.label} as the collateral
					</div>
					<div className={styles.infoTokenText}>
						The formula for this curve is <img src={FORMULA}></img>{" "}
					</div>
					<Button onClick={getInfoPage(true)} link>
						learn more
					</Button>{" "}
					<div className={styles.learnMore}>
						<Button onClick={getInfoPage(false, 0)} link>
							Go to the Main Page
						</Button>
					</div>
				</>
			) : (
				<InfoToken
					label={ecosystemTokens?.BONDED_TOKEN?.label}
					decimals={ecosystemTokens?.BONDED_TOKEN?.formatUnits}
				/>
			)}
		</>
	);
};
