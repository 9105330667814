import {
	TransactionStatus,
	useCall,
	useContractFunction,
	useEthers,
	useNotifications,
} from "@usedapp/core";
import axios from "axios";
import { Contract, ethers } from "ethers";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import TokenFactoryABI from "../abis/token-factory.json";
import { NETWORKS_LIST } from "../constants/networks";
import { TOKEN_FACTORIES } from "../constants/tokenFactories";
import { useEcosystem } from "../contexts/EcosystemContext";
import { Ecosystem, FetchProviders } from "../contexts/EcosystemContext/EcosystemContext";
import formatIconRefUrl from "../helpers/formatIconRefUrl";
import { relativelyUrl } from "../helpers/relativelyUrl";
import { useProvider } from "../helpers/useProvider";
import { IPairToken, IToken, TABI } from "../types";

const PAGE_SIZE = 20;
const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export type EcosystemInfo = {
	baseUrl: string;
	bondedDecimals: ethers.BigNumber;
	bondedName: string;
	bondedReferenceUrl: string;
	bondedSymbol: string;
	bondedToken: string;
	bondingCurve: string;
	code: string;
	collateralDecimals: ethers.BigNumber;
	collateralName: string;
	collateralReferenceUrl: string;
	collateralSymbol: string;
	collateralToken: string;
	ethBroker: string;
};

export type EcosystemTokens = {
	BONDED_TOKEN: IToken;
	COLLATERAL_TOKEN: IToken;
	UNISWAP: IToken;
};

export function useEcosystemInfo(ecosystem: string): {
	isLoading: boolean;
	data: EcosystemInfo | null;
} {
	const { chainId } = useProvider();
	const networkName = chainId ? NETWORKS_LIST[chainId] : null;
	const tokenFactoryAddress = networkName ? (TOKEN_FACTORIES as any)[networkName] : null;

	const { value, error } =
		useCall(
			tokenFactoryAddress && {
				contract: new Contract(tokenFactoryAddress, TokenFactoryABI),
				method: "getEcosystemByCode",
				args: [ecosystem],
			},
			{
				isStatic: true,
			},
		) ?? {};

	if (error) {
		return { isLoading: false, data: null };
	} else if (value) {
		return { isLoading: false, data: value?.[0] };
	} else {
		return { isLoading: true, data: null };
	}
}

export function useEcosystemConfig(baseUrl: string | null | undefined) {
	return useQuery<Ecosystem>(["ecosystem-config", baseUrl], async () => {
		if (!baseUrl) return null;
		const configPath = relativelyUrl(baseUrl) + "config.json";
		const { data } = await axios.get(configPath);
		return data;
	});
}

export function useProvidersConfig(baseUrl: string | null | undefined) {
	return useQuery<FetchProviders>(["ecosystem-providers", baseUrl], async () => {
		if (!baseUrl) return null;
		const configPath = relativelyUrl(baseUrl) + "PROVIDERS_BASE.json";
		const { data } = await axios.get(configPath);
		return data;
	});
}

export function useEcosystemSummary(ecosystem: string):
	| {
			isLoading: true;
			isEcosystemNotFound: false;
	  }
	| {
			isLoading: false;
			isEcosystemNotFound: true;
	  }
	| {
			isLoading: false;
			isEcosystemNotFound: false;
			ecosystemInfo: EcosystemInfo;
			ecosystemConfig: Ecosystem;
			providersConfig: FetchProviders;
			ecosystemTokens: EcosystemTokens;
			ecosystemTokenPairs: IPairToken[];
	  } {
	const ecosystemInfo = useEcosystemInfo(ecosystem);
	const ecosystemConfig = useEcosystemConfig(ecosystemInfo.data?.baseUrl);
	const providersConfig = useProvidersConfig(ecosystemInfo.data?.baseUrl);

	if (ecosystemInfo.isLoading || ecosystemConfig.isLoading || providersConfig.isLoading) {
		return { isLoading: true, isEcosystemNotFound: false };
	} else if (!ecosystemInfo.data || !ecosystemConfig.data || !providersConfig.data) {
		return {
			isLoading: false,
			isEcosystemNotFound: true,
		};
	} else {
		const ecosystemTokens = {
			BONDED_TOKEN: {
				address: ecosystemInfo.data.bondedToken,
				icon: formatIconRefUrl(ecosystemInfo.data.bondedReferenceUrl),
				label: ecosystemInfo.data.bondedSymbol,
				curveAddress: ecosystemInfo.data.bondingCurve,
				formatUnits: ecosystemInfo.data.bondedDecimals.toNumber(),
				ABI: "BONDED_TOKEN" as TABI,
			},
			COLLATERAL_TOKEN: {
				address: ecosystemInfo.data.collateralToken,
				icon: formatIconRefUrl(ecosystemInfo.data.collateralReferenceUrl),
				label: ecosystemInfo.data.collateralSymbol,
				formatUnits: ecosystemInfo.data.collateralDecimals.toNumber(),
				curveAddress: ecosystemInfo.data.bondingCurve,
				ABI: "COLLATERAL_TOKEN" as TABI,
			},
			UNISWAP: {
				address: ecosystemInfo.data.ethBroker, // eth_broker
				icon: "/logos/eth.svg",
				label: "ETH",
				formatUnits: 18,
				curveAddress: ecosystemInfo.data.ethBroker,
				ABI: "UNISWAP" as TABI,
			},
		};

		const ecosystemTokenPairs = [
			{
				_id: `${ecosystemTokens.COLLATERAL_TOKEN.label}/${ecosystemTokens.BONDED_TOKEN.label}`,
				sell: ecosystemTokens.COLLATERAL_TOKEN,
				buy: ecosystemTokens.BONDED_TOKEN,
			},
			{
				_id: `${ecosystemTokens.UNISWAP.label}/${ecosystemTokens.BONDED_TOKEN.label}`,
				sell: ecosystemTokens.UNISWAP,
				buy: ecosystemTokens.BONDED_TOKEN,
			},
		];

		return {
			isLoading: false,
			isEcosystemNotFound: false,
			ecosystemInfo: ecosystemInfo.data,
			ecosystemConfig: ecosystemConfig.data,
			providersConfig: providersConfig.data,
			ecosystemTokens: ecosystemTokens,
			ecosystemTokenPairs,
		};
	}
}

export function useCreateToken(): {
	status: TransactionStatus;
	createToken: (
		symbol: string,
		name: string,
		icon: string,
		curveTemplate?: string,
		tokenTemplate?: string,
		additionalCollaterals?: { addr: string; referenceUrl: string }[],
	) => Promise<ethers.providers.TransactionReceipt | undefined>;
	resetState: () => void;
} {
	const { chainId } = useProvider();
	const { ecosystem } = useEcosystem();
	const networkName = chainId ? NETWORKS_LIST[chainId] : null;
	const tokenFactoryAddress = networkName ? (TOKEN_FACTORIES as any)[networkName] : null;

	const contract = tokenFactoryAddress
		? new Contract(tokenFactoryAddress, TokenFactoryABI)
		: undefined;

	const { state, send, resetState } = useContractFunction(contract, "createToken", {
		transactionName: "CreateToken",
	});

	const { addNotification } = useNotifications();

	useEffect(() => {
		if (state.status === "Exception") {
			addNotification({
				chainId: state.chainId,
				notification: {
					type: "transactionRejected",
					submittedAt: Date.now(),
					...state,
				},
			} as any);
		}
	}, [state]);

	function createToken(
		symbol: string,
		name: string,
		icon: File | string,
		curveTemplate = ZERO_ADDRESS,
		tokenTemplate = ZERO_ADDRESS,
		additionalCollaterals: { addr: string; referenceUrl: string }[] = [],
	) {
		return send(ecosystem, symbol, name, icon, curveTemplate, tokenTemplate, additionalCollaterals);
	}

	return {
		status: state,
		createToken,
		resetState,
	};
}

export function useApplicationTokenPairs(ecosystem: string): {
	isLoading: boolean;
	data: IPairToken[];
	total: number;
	fetchMore: () => Promise<void>;
} {
	const { chainId } = useProvider();
	const { library } = useEthers();
	const { ecosystemTokens } = useEcosystem();
	const networkName = chainId ? NETWORKS_LIST[chainId] : null;
	const tokenFactoryAddress = networkName ? (TOKEN_FACTORIES as any)[networkName] : null;

	const [data, setData] = useState<IPairToken[]>([]);
	const [isLoading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [total, setTotal] = useState<number | null>(null);

	async function _fetchTotal() {
		if (total !== null) return total;

		const contract = new Contract(tokenFactoryAddress, TokenFactoryABI, library);
		const totalCountBig = await contract.tokenCountInEcosystem(ecosystem);
		const totalCount = parseInt(totalCountBig.toString());
		setTotal(totalCount);
		return totalCount;
	}

	async function fetchMore() {
		if (total !== null && total <= data.length) return;
		if (!library) return;

		try {
			setLoading(true);

			const total = await _fetchTotal();
			if (offset >= total) return;

			const contract = new Contract(tokenFactoryAddress, TokenFactoryABI, library);
			const page = await contract.getTokensByEcosystem(ecosystem, offset, PAGE_SIZE);

			const _mapFromRawToken = (raw: any): IToken => ({
				icon: raw.referenceUrl ? formatIconRefUrl(raw.referenceUrl) : "",
				address: raw.addr,
				label: raw.symbol,
				formatUnits: raw.decimals.toNumber(),
				curveAddress: raw.bondingCurve,
				ABI: "BONDED_TOKEN" as TABI, // ToDo: I'm not sure that it's correct. Refactor it.
			});

			const _toPair = (sell: IToken, buy: IToken): IPairToken => ({
				_id: `${sell.label}/${buy.label}`,
				sell,
				buy,
			});

			const rawTokens = page.filter((token: any) => !!token.symbol);

			const pairs: IPairToken[] = rawTokens
				.map((token: any) => [
					// main collateral (ecosystem token)
					_toPair(ecosystemTokens.BONDED_TOKEN, _mapFromRawToken(token)),
					// additional collaterals
					...token.additionalBondingCurves.map((additional: any) =>
						_toPair(
							{ ..._mapFromRawToken(additional), ABI: "COLLATERAL_TOKEN" },
							{ ..._mapFromRawToken(token), curveAddress: additional.bondingCurve },
						),
					),
				])
				.flat();

			setOffset(offset + PAGE_SIZE);
			setData([...data, ...pairs]);
		} catch (e: any) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	}

	return { isLoading, fetchMore, data, total: total ?? 0 };
}
