import cn from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import styles from "./WalletConnect.module.scss";

export interface WalletConnectProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	onClick?: () => any;
	children: ReactNode;
	loading?: boolean;
}

export const WalletConnect = (props: WalletConnectProps) => {
	const { children, onClick, loading = false, disabled, ...p } = props;

	return (
		<button
			className={cn(styles.button, "transition")}
			onClick={onClick}
			{...p}
			disabled={disabled || loading}
		>
			{children}
			{loading && <span className={styles.loading}>...</span>}
		</button>
	);
};
