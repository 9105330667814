import cn from "classnames";
import { createRef, ReactElement, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Triangle } from "../../assets/images/icons/alert-triangle.svg";
import { ReactComponent as Globe } from "../../assets/images/icons/globe-icon.svg";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import useOnClickOutside from "../../hooks/useClickOutside";
import NetworkPopup from "../NetworkPopup";
import Wallet from "../Wallet";
import styles from "./Header.module.scss";
import Logo from "./Logo";

export interface HeaderProps {
	ecosystemLogoUrl?: string;
	network: string;
	account: string | null | undefined;
	isConnectionDisrupted: boolean;
	onConnected: () => void;
	onDisconnect: () => void;
}

export const Header = ({
	onConnected,
	onDisconnect,
	ecosystemLogoUrl,
	network,
	account,
	isConnectionDisrupted,
}: HeaderProps): ReactElement => {
	const [isModalOpen, setModalOpen] = useState(false);

	const providerWrapperRef = createRef<HTMLDivElement>();
	const providerWrapperToggleButtonRef = useRef<HTMLElement>(null);

	const clickOutsideExclusions = [providerWrapperToggleButtonRef.current as Node];

	useOnClickOutside(
		providerWrapperRef,
		() => {
			setModalOpen(false);
		},
		clickOutsideExclusions,
	);

	const handleModalOpen = () => {
		if (isModalOpen) setModalOpen(false);
		else setModalOpen(true);
	};

	return (
		<header className={styles.root}>
			<div className={styles.container}>
				<div className={styles.headerGroup}>
					<Link
						style={{ transition: "all 0.5s ease" }}
						className={styles.logoAnimation}
						to="exchange"
						data-testid="header-to-exchange-link"
					>
						<Logo url={ecosystemLogoUrl ?? ""}></Logo>
					</Link>
					<div className={styles.network}>
						<Globe />
						<span className={styles.networkTitle}>Network:</span>
						{isConnectionDisrupted && <Triangle />}
						<span
							data-testid="network-selector-button"
							ref={providerWrapperToggleButtonRef}
							onClick={handleModalOpen}
							className={cn(styles.connect, {
								[styles.error]: isConnectionDisrupted,
							})}
						>
							{capitalizeFirstLetter(network)}
						</span>
					</div>

					<NetworkPopup ref={providerWrapperRef} isOpen={isModalOpen} />
				</div>

				<div className={styles.headerGroup}>
					<Wallet
						account={account}
						network={network}
						onConnected={onConnected}
						onDisconnect={onDisconnect}
					/>
				</div>
			</div>
		</header>
	);
};
