import { CSSProperties, ReactNode } from "react";
import styles from "./CreateTokenTxModal.module.scss";

type CreateTokenTxModalProps = {
	children: ReactNode;
	style?: CSSProperties;
	network: string;
	tokenName: string | undefined;
	ecosystemToken: string;
	tokenSymbol: string | undefined;
	collateralToken: string | undefined;
};

const CreateTokenTxModal = ({
	children,
	style,
	network,
	tokenName,
	ecosystemToken,
	tokenSymbol,
	collateralToken,
}: CreateTokenTxModalProps) => {
	return (
		<div style={style} className={styles.rootModal} data-testid="create-collateral-token-modal">
			<div className={styles.modalBlock}>
				<span className={styles.modalTitle}>Transaction Confirmation</span>

				<div className={styles.modalInfo}>
					<span className={styles.modalInfoText}>
						You are creating {tokenName} ({tokenSymbol}) in {network} network. It will use a
						multiple bonding curve with {collateralToken} and {ecosystemToken} tokens as the
						collateral. After initial transaction you will be routed to exchange page.
					</span>
				</div>
			</div>
			{children}
		</div>
	);
};

export default CreateTokenTxModal;
