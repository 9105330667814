import cn from "classnames";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { useRpc } from "../../../contexts/RpcProviderContext";
import Providers from "../Providers";
import styles from "./SelectNetwork.module.scss";

export type SelectNetworkProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const SelectNetwork: FC<SelectNetworkProps> = ({ ...props }) => {
	const {
		supportedNetworks,
		currentChainId,
		isAutoProviderSelection,
		setAutoProviderSelection,
		switchNetwork,
	} = useRpc();

	function handleAutoProviderChange(networkTitle: string, isAuto: boolean) {
		setAutoProviderSelection(isAuto);
	}

	function handleNetworkSelect(chainId: number) {
		switchNetwork(chainId);
	}

	return (
		<div className={cn(styles.wrapper)} {...props}>
			<h3 className={styles.title}>Select a network</h3>

			<ul data-testid="network-selector-network-list" className={styles.networks}>
				{supportedNetworks.map((x) => (
					<li className={styles.network} key={x.chainId}>
						<Providers
							isUnderDevelopment={x.isUnderDevelopment}
							isActive={x.chainId === currentChainId}
							title={x.title}
							isAutoProviderSelection={
								x.chainId === currentChainId ? isAutoProviderSelection : false
							}
							chainId={x.chainId}
							onAutoProviderChange={handleAutoProviderChange}
							onNetworkSelect={() => handleNetworkSelect(x.chainId)}
						/>
					</li>
				))}
			</ul>

			<span className={styles.bottom}>This window auto-saves your changes</span>
		</div>
	);
};
