import { createContext } from "react";

export type RpcProviderConfig = {
	title: string;
	url: string;
	isCustom: boolean;
};

export type RpcContextState = {
	supportedNetworks: {
		chainId: number;
		title: string;
		isUnderDevelopment: boolean;
	}[];
	rpcProviders: RpcProviderConfig[];
	currentChainId: number;
	selectedRpcProvider: string | null;
	isAutoProviderSelection: boolean;
	setAutoProviderSelection: (value: boolean) => void;
	selectRpcProvider: (url: string) => void;
	addCustomRpcProvider: (url: string) => Promise<void>;
	removeCustomRpcProvider: (url: string) => void;
	switchNetwork: (chainId: number) => void;
	error: Error | undefined;
	resetError: () => void;
};

export const contextDefaultValues: RpcContextState = {
	supportedNetworks: [],
	rpcProviders: [],
	currentChainId: 1,
	selectedRpcProvider: null,
	isAutoProviderSelection: true,
	setAutoProviderSelection: () => undefined,
	selectRpcProvider: () => undefined,
	addCustomRpcProvider: () => Promise.resolve(),
	removeCustomRpcProvider: () => undefined,
	switchNetwork: () => undefined,
	error: undefined,
	resetError: () => undefined,
};

export const RpcContext = createContext<RpcContextState>(contextDefaultValues);
