const linkExternalCss = (url: string) => {
	const linked = document.querySelector("#theme");

	if (linked) {
		const prevUrl = linked.getAttribute("href");
		if (prevUrl !== url) {
			linked.setAttribute("href", url);
		}
		return;
	}

	const link = document.createElement("link");
	link.setAttribute("id", "theme");
	link.setAttribute("rel", "stylesheet");
	link.setAttribute("type", "text/css");
	link.setAttribute("href", url);
	const head = document.getElementsByTagName("head")[0];
	head.prepend(link);
};

export default linkExternalCss;
