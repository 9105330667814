import cn from "classnames";
import { CSSProperties, ReactNode } from "react";
import styles from "./Card.module.scss";

type CardProps = {
	children: ReactNode;
	transparent?: boolean;
	style?: CSSProperties;
};

const Card = ({ children, style, transparent }: CardProps) => {
	return (
		<div style={style} className={cn(styles.root, { [styles.transparent]: transparent })}>
			{children}
		</div>
	);
};

export default Card;
