import { RefObject, useEffect } from "react";

type Handler = (event: MouseEvent) => void;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: Handler,
	excludedNodes?: Node[],
): void {
	useEffect(() => {
		const listener = (event: MouseEvent) => {
			if (!ref.current || ref.current.contains(event.target as Node)) {
				return;
			}

			if (excludedNodes && excludedNodes.includes(event.target as Node)) return;

			handler(event);
		};
		document.addEventListener("mousedown", listener);
		// document.addEventListener("touchstart", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
			// document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler, excludedNodes]);
}

export default useOnClickOutside;
