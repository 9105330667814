import { useCall } from "@usedapp/core";
import { Contract, ethers } from "ethers";
import { useEffect } from "react";
import { useRpc } from "../contexts/RpcProviderContext";

export type LoadingCallParameters = {
	contractAddress?: string;
	abi?: ethers.ContractInterface;
	method?: string;
	args?: any[];
};

export function useLoadingCall({ contractAddress, abi, method, args }: LoadingCallParameters): {
	isLoading: boolean;
	value: any;
	error: Error | undefined;
} {
	const { resetError } = useRpc();
	const { value, error } =
		useCall(
			contractAddress &&
				abi &&
				method &&
				args && {
					contract: new Contract(contractAddress, abi),
					method: method,
					args: args,
				},
		) ?? {};

	useEffect(() => {
		if (!error && value) {
			resetError();
		}
	}, [error, value, resetError]);

	if (error) {
		return { isLoading: false, value: null, error };
	} else if (value) {
		return { isLoading: false, value: value, error };
	} else {
		return { isLoading: true, value: null, error };
	}
}
