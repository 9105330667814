import cn from "classnames";
import React, { FC, ReactElement } from "react";
import { ReactComponent as ArrowsIcon } from "../../assets/images/icons/arrows.svg";
import styles from "./LegacyButton.module.scss";

import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

export interface ButtonProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	appearance?: "button" | "icon" | "exchange" | "add-token" | "provider";
	children?: ReactNode;
	loading?: boolean;
}

export const LegacyButton: FC<ButtonProps> = ({
	appearance = "button",
	children = "Click",
	onClick,
	disabled = false,
	loading = false,
	...props
}: ButtonProps): ReactElement => {
	return (
		<button
			data-testid="variants-network"
			className={cn(styles.button, {
				[styles.appearanceButton]: appearance === "button",
				[styles.appearanceExchange]: appearance === "exchange",
				[styles.appearanceIcon]: appearance === "icon",
				[styles.addToken]: appearance === "add-token",
				[styles.provider]: appearance === "provider",
			})}
			{...props}
			disabled={disabled}
			onClick={onClick}
		>
			{appearance === "icon" ? (
				<ArrowsIcon />
			) : (
				<React.Fragment>
					{children}
					{loading && <span className={styles.loading}>...</span>}
				</React.Fragment>
			)}
		</button>
	);
};
