import { useState } from "react";
import CreateCard from "./CreateCard";
import styles from "./CreateToken.module.scss";
import InfoCard, { CollateralToken } from "./InfoCard";

const CreateToken = () => {
	const [page, setPage] = useState(0);
	const [dataCollateralToken, setDataCollateralToken] = useState<CollateralToken[]>([]);
	const [isLearnMore, setLearnMore] = useState<boolean>(false);
	const [isDisabledInfoPage, setDisabledInfoPage] = useState<boolean>(false);

	return (
		<div className={styles.root}>
			<div className={styles.create}>
				<CreateCard
					dataCollateralToken={dataCollateralToken}
					setPage={setPage}
					setLearnMore={setLearnMore}
					setDisabledInfoPage={setDisabledInfoPage}
					isDisabledInfoPage={isDisabledInfoPage}
				/>
			</div>
			<div className={styles.info}>
				<InfoCard
					setDataCollateralToken={setDataCollateralToken}
					page={page}
					setPage={setPage}
					isLearnMore={isLearnMore}
					setLearnMore={setLearnMore}
					isDisabledInfoPage={isDisabledInfoPage}
				/>
			</div>
		</div>
	);
};

export default CreateToken;
