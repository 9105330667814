import { useEtherBalance, useEthers, useTokenBalance } from "@usedapp/core";
import { ethers } from "ethers";
import { IToken } from "../types";

export function useBalance(token?: IToken | null) {
	const { account } = useEthers();
	const ethBalance = useEtherBalance(token?.ABI === "UNISWAP" ? account : null);
	const tokenBalance = useTokenBalance(token?.ABI !== "UNISWAP" ? token?.address : null, account);
	const balance = token?.ABI === "UNISWAP" ? ethBalance : tokenBalance;
	const balanceFormatted = balance ? ethers.utils.formatUnits(balance, token?.formatUnits) : null;
	return balanceFormatted;
}
