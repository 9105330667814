import { ReactComponent as Spinner } from "../../../assets/images/spinner.svg";
import styles from "./Loader.module.scss";

interface Props {
	message?: string | null;
}

const Loader = ({ message }: Props) => {
	return (
		<div className={styles.root}>
			<Spinner className={styles.spinner} />
			{message ? <div className={styles.message}>{message}</div> : null}
		</div>
	);
};

export default Loader;
