import { useChainMeta } from "@usedapp/core";
import cn from "classnames";
import React, { FC, ReactElement, ReactNode } from "react";
import { ReactComponent as CheckError } from "../../assets/images/icons/check-erorr.svg";
import { ReactComponent as Installed } from "../../assets/images/icons/installed.svg";
import { ReactComponent as Loader } from "../../assets/images/icons/loader.svg";
import { useProvider } from "../../helpers/useProvider";
import "./Notification.scss";

const HEADER_MESSAGES = {
	success: (
		<React.Fragment>
			<Installed />
			<h6 data-testid="notification-finished" className="notification-custom-title finished">
				Transaction finished
			</h6>
		</React.Fragment>
	),
	error: (
		<React.Fragment>
			<CheckError />
			<h6 data-testid="notification-error" className="notification-custom-title error">
				Transaction error
			</h6>
		</React.Fragment>
	),
	loading: (
		<React.Fragment>
			<Loader className="notification-custom-animate" />
			<h6 data-testid="notification-started" className="notification-custom-title started">
				Transaction started
			</h6>
		</React.Fragment>
	),
	"connection-error": (
		<React.Fragment>
			<CheckError />
			<h6 className="notification-custom-title error">Connection error</h6>
		</React.Fragment>
	),
	"network-switch": (
		<React.Fragment>
			<CheckError className="icon-switch-network" />
			<h6 className="notification-custom-title switch">Network changed</h6>
		</React.Fragment>
	),
};

export type ToastType = "success" | "error" | "loading" | "connection-error" | "network-switch";

export interface ToastProps {
	hash?: string | null;
	type: ToastType;
	messageError?: string;
	message?: string | ReactNode | ReactElement | null;
}

export const Notification: FC<ToastProps> = (props) => {
	const { hash, type, messageError, message = null } = props;
	const { chainId } = useProvider();
	const chain = useChainMeta(chainId ?? 1); // ToDo: use DEFAULT_CHAIN_ID

	const isLoading = type === "loading";
	const isSuccess = type === "success";
	const isError = type === "error" || type === "connection-error";
	const isMessage = message;

	const transactionLink = hash && chainId ? chain.getExplorerTransactionLink(hash) : null;

	return (
		<div
			data-testid="notification-modal"
			className={cn("notification-custom-wrapper", {
				"notification-custom-error": isError,
				"notification-custom-loading": isLoading,
				"notification-custom-success": isSuccess,
			})}
		>
			<header className={cn("notification-custom-header")}>{HEADER_MESSAGES[type]}</header>
			<p className={cn("notification-custom-text")}>
				{transactionLink && (
					<React.Fragment>
						You can track your transaction
						<a
							href={transactionLink}
							className="notification-custom-link"
							target="_blank"
							rel="noreferrer"
						>
							Show More
						</a>
					</React.Fragment>
				)}

				{isError && messageError && messageError.length > 0 && (
					<span data-testid="error-modal-message" className="notification-custom-text-error">
						{messageError}
					</span>
				)}

				{isMessage && <span>{message}</span>}
			</p>
		</div>
	);
};
