import cn from "classnames";
import { ChangeEvent, useState } from "react";
import icon from "../../../assets/images/icons/Ethereum.svg";
import { ReactComponent as Plus } from "../../../assets/images/icons/plus-circle.svg";
import { ReactComponent as Arrow } from "../../../assets/images/icons/providers-arrow.svg";
import { ERROR_MESSAGES } from "../../../constants";
import { useRpc } from "../../../contexts/RpcProviderContext";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { useToggle } from "../../../hooks/useToggle";
import Checkbox from "../../Checkbox";
import LegacyButton from "../../LegacyButton";
import { customErrorToast } from "../../Notification";
import Provider from "../Provider";
import styles from "./Providers.module.scss";

export interface ProvidersProps {
	isActive: boolean;
	isAutoProviderSelection: boolean;
	title: string;
	chainId: number;
	isUnderDevelopment: boolean;
	onAutoProviderChange: (title: string, checked: boolean) => void;
	onNetworkSelect: () => void;
}

export const Providers = (props: ProvidersProps) => {
	const [isProviderLoading, setProviderLoading] = useState(false);
	const [isOpen, onOpen] = useToggle(true);

	const {
		isActive,
		isAutoProviderSelection,
		title,
		isUnderDevelopment,
		onAutoProviderChange,
		onNetworkSelect,
	} = props;

	const {
		addCustomRpcProvider,
		rpcProviders,
		selectedRpcProvider,
		error: providerError,
		selectRpcProvider,
		removeCustomRpcProvider,
	} = useRpc();

	const [isProviderCreating, toggleProviderCreating] = useToggle(false);
	const [providerInputValue, setProviderInputValue] = useState("");

	const selectedRpcProviderDetails = rpcProviders.find((x) => x.url === selectedRpcProvider);

	function handleProviderInputChange(event: ChangeEvent<HTMLInputElement>) {
		setProviderInputValue(event.target.value);
	}

	function handleCreateProviderClick() {
		toggleProviderCreating();
	}

	function handleSelectProvider(rpcUrl: string) {
		selectRpcProvider(rpcUrl);
	}

	function handleRemoveProvider(rpcUrl: string) {
		removeCustomRpcProvider(rpcUrl);
	}

	async function handleSubmitForm(event: ChangeEvent<HTMLFormElement>) {
		event.preventDefault();

		if (!providerInputValue) return;
		if (providerInputValue.length <= 0) return;

		try {
			new URL(providerInputValue);
		} catch (error) {
			customErrorToast(ERROR_MESSAGES.PROVIDER_ADDITION_INCORRECT_ADDRESS, "connection-error");
			return;
		}

		try {
			setProviderLoading(true);
			await addCustomRpcProvider(providerInputValue);
			toggleProviderCreating();
		} catch (error: any) {
			customErrorToast(error.message, "connection-error");
		} finally {
			setProviderLoading(false);
		}
	}

	const isRenderNetworks = !isAutoProviderSelection && isOpen;

	return (
		<div className={cn(styles.network, { [styles.isActive]: isActive })}>
			<div className={styles.networkTop}>
				<div className={styles.networkInfo}>
					<img src={icon} alt={title} />
					<h4 className={cn(styles.networkTitle, styles.flex)}>{capitalizeFirstLetter(title)}</h4>
					{isUnderDevelopment && <span className={styles.mainnet}>(Under development)</span>}
				</div>

				{!isActive && !isUnderDevelopment && (
					<LegacyButton
						appearance="provider"
						className={cn(styles.setActive, "transition")}
						onClick={onNetworkSelect}
					>
						Set active
					</LegacyButton>
				)}

				{isActive && (
					<div
						data-testid="network-status"
						className={cn(styles.networkActive, {
							[styles.isOpen]: isOpen,
							[styles.networkError]: !!providerError,
						})}
					>
						{providerError ? <span>Error</span> : <span>Active</span>}
						{!isAutoProviderSelection && <Arrow onClick={() => onOpen()} />}
					</div>
				)}
			</div>
			{isActive && (
				<div>
					<ul data-testid="provider-list" className={styles.providersList}>
						<li className={cn(styles.provider)}>
							<div className={styles.label}>
								<h5 className={cn(styles.providerTitle, styles.bold)}>
									Provider
									{isAutoProviderSelection && ` / ${selectedRpcProviderDetails?.title}`}
									{!isAutoProviderSelection &&
										!isOpen &&
										` / ${selectedRpcProviderDetails?.title ?? "Custom"}`}
								</h5>
								<label className={cn(styles.checkbox)}>
									<span className={styles.providerTitle}>Auto</span>
									<Checkbox
										checked={isAutoProviderSelection}
										onChange={() => onAutoProviderChange(title, !isAutoProviderSelection)}
									/>
								</label>
							</div>
						</li>

						{isRenderNetworks &&
							rpcProviders.map((x) => (
								<li data-testid="provider-list-item" className={styles.provider} key={x.url}>
									<Provider
										title={x.title}
										isError={x.url === selectedRpcProvider ? !!providerError : false}
										isActive={x.url === selectedRpcProvider}
										onRemoveProvider={x.isCustom ? () => handleRemoveProvider(x.url) : undefined}
										onSelectProvider={() => handleSelectProvider(x.url)}
									/>
								</li>
							))}
					</ul>

					{isProviderCreating && (
						<form
							data-testid="add-provider-form"
							className={styles.form}
							onSubmit={handleSubmitForm}
						>
							<input
								type="text"
								onChange={handleProviderInputChange}
								value={providerInputValue}
								autoFocus
								className={styles.input}
								placeholder="Enter Provider (press enter to save)"
								data-testid="provider-input"
								disabled={isProviderLoading}
							/>
						</form>
					)}

					{!isAutoProviderSelection && (
						<button
							data-testid="add-provider-button"
							className={styles.button}
							onClick={handleCreateProviderClick}
						>
							<Plus /> <span>Add provider</span>
						</button>
					)}
				</div>
			)}
		</div>
	);
};
