import { useEthers } from "@usedapp/core";
import { useLocalStorage } from "@usedapp/core/dist/esm/src/hooks";
import { FC, ReactElement, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import { Notifications } from "../../components/Notifications";
import { Loader } from "../../components/Shared";
import { DEFAULT_THEME } from "../../constants";
import { useEcosystem } from "../../contexts/EcosystemContext/useEcosystem";
import { useRpc } from "../../contexts/RpcProviderContext";
import { useTheme } from "../../hooks/useTheme";
import CreateToken from "../CreateToken";
import EcosystemNotFound from "../EcosystemNotFound";
import Exchange from "../Exchange";
import PageNotFound from "../PageNotFound";
import styles from "./App.module.scss";

export const App: FC = (): ReactElement => {
	const { activateBrowserWallet, deactivate, account } = useEthers();
	const { network, ecosystemConfig, isLoading, isEcosystemNotFound } = useEcosystem();
	const { pathname } = useLocation();
	const { error } = useRpc();

	const [autoConnectTag] = useLocalStorage("usedapp:autoConnectTag");

	// It's workaround to autoconnect MetaMask wallet
	// Usedapp's built-in autoconnection connects MetaMask even it's locked
	useEffect(() => {
		if (autoConnectTag === "metamask" && window.ethereum) {
			window.ethereum
				.request({ method: "eth_accounts", params: [] })
				.then((accounts: string[]) => {
					if (accounts.length > 0) activateBrowserWallet();
				})
				.catch(() => console.log("Cannot get accounts from MetaMask"));
		}
	}, []);

	useTheme(ecosystemConfig?.theme?.styles ? ecosystemConfig?.theme : DEFAULT_THEME);

	if (isEcosystemNotFound && !pathname.includes("ecosystem-not-found")) {
		return <Navigate to="ecosystem-not-found" replace />;
	} else if (!isEcosystemNotFound && pathname.includes("ecosystem-not-found")) {
		return <Navigate to="exchange" replace />;
	}

	return (
		<div className={styles.root}>
			{isLoading && <Loader />}

			<Header
				ecosystemLogoUrl={ecosystemConfig?.theme.logo}
				network={network}
				account={account}
				isConnectionDisrupted={!!error}
				onConnected={activateBrowserWallet}
				onDisconnect={deactivate}
			/>

			<main className={styles.main}>
				<Toaster containerStyle={{ position: "absolute", right: 0, left: "auto", top: 10 }} />

				<Notifications />

				<div className={styles.content}>
					<Routes>
						<Route path="" element={<Navigate to={`exchange`} replace />} />
						<Route path="exchange" element={<Exchange />} />
						<Route path="create-token" element={<CreateToken />} />
						{/* <Route path="available-tokens" element={<AvailableTokens />} /> */}
						<Route path="ecosystem-not-found" element={<EcosystemNotFound />} />
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</div>
			</main>
		</div>
	);
};
