import { useState } from "react";

export const useToggle = (
	initialState: boolean,
): [isToggle: boolean, toggle: (value?: boolean) => void] => {
	const [isToggle, setIsToggle] = useState<boolean>(initialState);

	const toggle = (value?: boolean) => {
		const bool = typeof value !== "undefined" && typeof value === "boolean" ? value : !isToggle;
		setIsToggle(bool);
	};

	return [isToggle, toggle];
};
