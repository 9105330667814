import "@fontsource/anonymous-pro";
import "@fontsource/montserrat";
import { Config, DAppProvider } from "@usedapp/core";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import NetworkValidator from "./components/NetworkValidator";
import { DEFAULT_CHAIN_ID, DEFAULT_ECOSYSTEM, DEFAULT_NETWORK } from "./constants";
import { NETWORKS_LIST } from "./constants/networks";
import { PROVIDERS } from "./constants/providers";
import { EcosystemProvider } from "./contexts/EcosystemContext";
import { RpcProvider } from "./contexts/RpcProviderContext";
import { createProvider } from "./helpers/createProvider";
import { getKeyByValue } from "./helpers/getKeyByValue";
import "./index.scss";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

// Parse a network from hash URL
const network = /^#\/([^/]*)\/([^/]*)/gm.exec(location.hash)?.[1] ?? DEFAULT_NETWORK;
const chainId = getKeyByValue(NETWORKS_LIST, network) ?? DEFAULT_CHAIN_ID;

// ToDo: refactor
const config: Config = {
	readOnlyChainId: chainId,
	// To prevent the error:
	// The final argument passed to useEffect changed size between renders. The order and size of this array must remain constant.
	readOnlyUrls: Object.fromEntries(
		Object.keys(PROVIDERS).map((k) => [
			getKeyByValue(NETWORKS_LIST, k) ?? DEFAULT_CHAIN_ID,
			createProvider(
				(PROVIDERS as any)[k]?.[0].url,
				getKeyByValue(NETWORKS_LIST, k) ?? DEFAULT_CHAIN_ID,
			),
		]),
	),
	noMetamaskDeactivate: true,
	autoConnect: false,
};

const DEFALUT_ROUTE = `/${DEFAULT_NETWORK}/${DEFAULT_ECOSYSTEM}`;

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
	<QueryClientProvider client={queryClient}>
		<DAppProvider config={config}>
			<HashRouter>
				<Routes>
					<Route
						path=":network/:ecosystem/*"
						element={
							<NetworkValidator>
								<EcosystemProvider>
									<RpcProvider>
										<App />
									</RpcProvider>
								</EcosystemProvider>
							</NetworkValidator>
						}
					/>
					<Route path="*" element={<Navigate to={DEFALUT_ROUTE} replace />} />
				</Routes>
			</HashRouter>
		</DAppProvider>
	</QueryClientProvider>,
);

reportWebVitals();
