import { TransactionStatus, useContractFunction, useNotifications } from "@usedapp/core";
import { Contract, ethers } from "ethers";
import { useEffect } from "react";
import BondingCurveABI from "../abis/bonding-curve.json";
import EthBrokerABI from "../abis/eth-broker.json";
import RateCalcABI from "../abis/rate-calc.json";
import { RATE_CALCS } from "../constants/rateCalcs";
import { useEcosystem } from "../contexts/EcosystemContext";
import { fractionDigitsCount } from "../helpers/fractionDigitsCount";
import { slippageOf } from "../helpers/slippageOf";
import { LoadingCallParameters, useLoadingCall } from "../hooks/useLoadingCall";
import { IPairToken } from "../types";
import { useTime } from "./ethBroker";

const { parseUnits, formatUnits } = ethers.utils;

export function useBuyPrice(
	bondingCurveAddress?: string,
	amount?: ethers.BigNumber,
): {
	isLoading: boolean;
	value: ethers.BigNumber | null;
	error: Error | undefined;
} {
	const { isLoading, value, error } = useLoadingCall({
		contractAddress: bondingCurveAddress,
		abi: BondingCurveABI,
		method: "buyPrice",
		args: [amount],
	});

	return { isLoading, value: value?.[0], error };
}

export function useSellReward(
	bondingCurveAddress?: string,
	amount?: ethers.BigNumber,
): {
	isLoading: boolean;
	value: ethers.BigNumber | null;
	error: Error | undefined;
} {
	const { isLoading, value, error } = useLoadingCall({
		contractAddress: bondingCurveAddress,
		abi: BondingCurveABI,
		method: "sellReward",
		args: [amount],
	});

	return { isLoading, value: value?.[0], error };
}

// HELPERS

export function useRate({
	selectedPair,
	swapDirection,
	fromAmount,
	toAmount,
}: {
	selectedPair: IPairToken | null;
	swapDirection: "buy" | "sell";
	fromAmount?: string;
	toAmount?: string;
}): {
	fromAmount?: string;
	toAmount?: string;
	isLoading: boolean;
} {
	const { buy, sell } = selectedPair ?? {};

	const { network } = useEcosystem();
	const rateCalcContractAddress: string = (RATE_CALCS as any)[network];

	const bondingCurve = swapDirection === "buy" ? buy?.curveAddress : sell?.curveAddress;

	const parsedFromAmount =
		fromAmount && sell && fractionDigitsCount(fromAmount) <= sell.formatUnits
			? parseUnits(fromAmount, sell.formatUnits)
			: null;

	const parsedToAmount =
		toAmount && buy && fractionDigitsCount(toAmount) <= buy.formatUnits
			? parseUnits(toAmount, buy.formatUnits)
			: null;

	let config: LoadingCallParameters | null = null;

	// ToDo: move this if-elses into separate function because this logic is needed in several places
	if (sell?.ABI === "UNISWAP" || buy?.ABI === "UNISWAP") {
		if (parsedFromAmount && swapDirection === "sell") {
			config = {
				contractAddress: buy?.curveAddress,
				abi: EthBrokerABI,
				method: "sellReward",
				args: [parsedFromAmount],
			};
		} else if (parsedToAmount && swapDirection === "buy") {
			config = {
				contractAddress: sell?.curveAddress,
				abi: EthBrokerABI,
				method: "buyPrice",
				args: [parsedToAmount],
			};
		}
	} else {
		if (parsedFromAmount && swapDirection === "buy") {
			config = {
				contractAddress: rateCalcContractAddress,
				abi: RateCalcABI,
				method: "buyRewardRange",
				args: [bondingCurve, parsedFromAmount],
			};
		} else if (parsedFromAmount && swapDirection === "sell") {
			config = {
				contractAddress: bondingCurve,
				abi: BondingCurveABI,
				method: "sellReward",
				args: [parsedFromAmount],
			};
		} else if (parsedToAmount && swapDirection === "buy") {
			config = {
				contractAddress: bondingCurve,
				abi: BondingCurveABI,
				method: "buyPrice",
				args: [parsedToAmount],
			};
		} else if (parsedToAmount && swapDirection === "sell") {
			config = {
				contractAddress: rateCalcContractAddress,
				abi: RateCalcABI,
				method: "sellPriceRange",
				args: [bondingCurve, parsedToAmount],
			};
		}
	}

	const { value, isLoading } = useLoadingCall(config ?? {});
	const amount = value?.[0];

	return {
		fromAmount: fromAmount ?? (amount && sell ? formatUnits(amount, sell.formatUnits) : undefined),
		toAmount: toAmount ?? (amount && buy ? formatUnits(amount, buy.formatUnits) : undefined),
		isLoading: config ? isLoading : false,
	};
}

export function useExchange(
	selectedPair: IPairToken | null,
	swapDirection: "buy" | "sell",
	fromAmount: string,
	toAmount: string,
	slippage: string,
): {
	status: TransactionStatus;
	exchange: () => Promise<void>;
	resetState: () => void;
} {
	const { sell, buy } = selectedPair ?? {};

	const bondingCurve = swapDirection === "buy" ? buy?.curveAddress : sell?.curveAddress;
	const parsedFromAmount =
		fromAmount && sell ? parseUnits(fromAmount, sell.formatUnits) : undefined;
	const parsedToAmount = toAmount && buy ? parseUnits(toAmount, buy.formatUnits) : undefined;
	const isEthBroker = sell?.ABI === "UNISWAP" || buy?.ABI === "UNISWAP";
	const ethBrokerAddress = isEthBroker
		? swapDirection === "sell"
			? buy?.curveAddress
			: sell?.curveAddress
		: undefined;

	// ToDo: refactor the complicated code
	// Only for ETH
	const { value: maxCollateralSpendAmount, isLoading: isBuyPriceLoading } = useBuyPrice(
		isEthBroker ? bondingCurve : undefined,
		swapDirection === "buy" ? parsedToAmount : undefined,
	);

	// ToDo: refactor the complicated code
	// Only for ETH
	const { value: minCollateralSellValue, isLoading: isSellRewardLoading } = useSellReward(
		isEthBroker ? bondingCurve : undefined,
		swapDirection === "sell" ? parsedFromAmount : undefined,
	);

	// Only for ETH
	const { value: time, isLoading: isTimeLoading } = useTime(ethBrokerAddress);

	let config: any = {};

	// ToDo: move this if-elses into separate function because this logic is needed in several places
	if (isEthBroker) {
		if (swapDirection === "sell") {
			config = {
				contractAddress: buy?.curveAddress,
				abi: EthBrokerABI,
				method: "redeem",
				args: [
					parsedFromAmount,
					minCollateralSellValue?.sub(slippageOf(minCollateralSellValue, slippage)),
					time?.add(300),
				],
			};
		} else if (swapDirection === "buy") {
			config = {
				contractAddress: sell?.curveAddress,
				abi: EthBrokerABI,
				method: "mint",
				args: [
					parsedToAmount,
					maxCollateralSpendAmount?.add(slippageOf(maxCollateralSpendAmount, slippage)),
					time?.add(300),
				],
				value: parsedFromAmount?.add(slippageOf(parsedFromAmount, slippage)),
			};
		}
	} else {
		if (swapDirection === "sell") {
			config = {
				contractAddress: bondingCurve,
				abi: BondingCurveABI,
				method: "redeem",
				args: [parsedFromAmount, parsedToAmount?.sub(slippageOf(parsedToAmount, slippage))],
			};
		} else if (swapDirection === "buy") {
			config = {
				contractAddress: bondingCurve,
				abi: BondingCurveABI,
				method: "mint",
				args: [parsedToAmount, parsedFromAmount?.add(slippageOf(parsedFromAmount, slippage))],
			};
		}
	}

	const contract =
		config.contractAddress && config.abi
			? new Contract(config.contractAddress, config.abi)
			: undefined;

	const { state, send, resetState } = useContractFunction(contract, config?.method ?? "", {
		transactionName: "Exchange",
	});

	const { addNotification } = useNotifications();

	useEffect(() => {
		if (state.status === "Exception") {
			addNotification({
				chainId: state.chainId,
				notification: {
					type: "transactionRejected",
					submittedAt: Date.now(),
					...state,
				},
			} as any);
		}
	}, [state]);

	async function exchange() {
		if (!config?.args) return;

		const isLoading = isEthBroker
			? swapDirection === "buy"
				? isBuyPriceLoading || isTimeLoading
				: isSellRewardLoading || isTimeLoading
			: false;

		if (isLoading) return;

		await send(...config.args, { value: config?.value });
	}

	return {
		status: state,
		exchange,
		resetState,
	};
}
