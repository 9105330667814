import { useEffect, useState } from "react";
import { relativelyUrl } from "../../helpers/relativelyUrl";

interface LogoProps {
	url: string;
}

const Logo = ({ url }: LogoProps) => {
	const [svg, setSvg] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);
	const [isErrored, setIsErrored] = useState(false);

	useEffect(() => {
		fetch(relativelyUrl(url))
			.then((res) => res.text())
			.then(setSvg)
			.catch(setIsErrored)
			.then(() => setIsLoaded(true));
	}, [url]);

	return (
		<div
			style={{ transition: "all 0.5s ease" }}
			className={`svgInline svgInline--${isLoaded ? "loaded" : "loading"} ${
				isErrored ? "svgInline--errored" : ""
			}`}
			dangerouslySetInnerHTML={{ __html: svg }}
		/>
	);
};

export default Logo;
