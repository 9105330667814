import toast from "react-hot-toast";
import { Notification, ToastProps, ToastType } from "./Notification";

export const customPromiseToast = async (
	promise: Promise<unknown>,
	hash: string | null,
	messageError?: string,
) => {
	await toast.promise(promise, {
		loading: <Notification hash={hash} type="loading" />,
		success: <Notification hash={hash} type="success" />,
		error: <Notification hash={null} type="error" messageError={messageError} />,
	});
};

export const customErrorToast = (messageError: string, type: ToastType = "error") => {
	toast.error(<Notification hash={null} type={type} messageError={messageError} />, {
		duration: 5000,
	});
};

export const customToast = ({
	hash,
	type,
	message,
	messageError,
	duration = 5000,
}: ToastProps & { duration?: number }) => {
	toast(<Notification hash={hash} type={type} messageError={messageError} message={message} />, {
		duration,
	});
};
