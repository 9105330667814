import Error from "../../components/Error";
import { DEFAULT_THEME } from "../../constants";
import { NETWORKS_LIST } from "../../constants/networks";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { useTheme } from "../../hooks/useTheme";
import styles from "../App/App.module.scss";

interface Props {
	account?: string;
	onDisconnect?: () => void;
}

export default function UnsupportedNetwork({ account, onDisconnect }: Props) {
	useTheme(DEFAULT_THEME);

	const supportedNetworks = Object.values(NETWORKS_LIST).map(capitalizeFirstLetter);

	return (
		<div className={styles.root}>
			<Error
				title="You are on an unsupported network."
				subtitle={
					"Please switch to " + supportedNetworks.map((item) => " " + item) + " to continue."
				}
				loggedIn={account}
				onDisconnect={onDisconnect}
			/>
		</div>
	);
}
