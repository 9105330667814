import { useEffect, useRef } from "react";

interface VanillaChildrenProps {
	children: HTMLElement | HTMLDivElement;
}

export const VanillaChildren = ({ children }: VanillaChildrenProps): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		ref.current?.appendChild(children);
	}, [ref]);

	return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} ref={ref} />
	);
};
