import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEcosystem } from "../../contexts/EcosystemContext";
import { stripNonNumerics } from "../../helpers/stripNonNumerics";
import { IPairToken } from "../../types";
import { useApplicationTokenPairs } from "../../wrappers/tokenFactory";
import SelectedPair from "../SelectedPair";
import { Divider } from "../Shared";
import { Pair } from "./Pair";
import styles from "./Pairs.module.scss";

export interface PairsProps {
	selectedPair: IPairToken | null;
	swapDirection: "buy" | "sell";
	ecosystemTokenPairs: IPairToken[];
	onVisiblePairs: () => void;
	onSwapDirectionChange: (swapDirection: "buy" | "sell") => void;
	onSelectedPairChange: (selectedPair: IPairToken) => void;
	setFromAmount: (x: any) => any;
	setToAmount: (x: any) => any;
}

export const Pairs = ({
	selectedPair,
	swapDirection,
	ecosystemTokenPairs,
	onVisiblePairs,
	onSwapDirectionChange,
	onSelectedPairChange,
	setFromAmount,
	setToAmount,
}: PairsProps) => {
	const { ecosystem } = useEcosystem();
	const { data: pairs, fetchMore, total, isLoading, data } = useApplicationTokenPairs(ecosystem);

	useEffect(() => {
		fetchMore();
	}, [fetchMore]);

	const handlerSelectedPair = (pair: IPairToken) => () => {
		const numerics = stripNonNumerics("0");
		if (numerics === null) return;
		onSelectedPairChange(pair);
		setFromAmount(numerics);
		setToAmount(numerics);
		onSwapDirectionChange("buy");
		onVisiblePairs();
	};

	return (
		<div className={styles.root}>
			<div className={styles.selectedPair}>
				<SelectedPair swapDirection={swapDirection} pair={selectedPair} onClick={onVisiblePairs} />
			</div>

			<InfiniteScroll
				dataLength={pairs.length}
				loader={<Loader />}
				next={fetchMore}
				hasMore={pairs.length < total}
				height={440}
			>
				<Divider>Ecosystem tokens</Divider>

				<ul className={styles.list}>
					{ecosystemTokenPairs.map((pair) => {
						return (
							<li
								key={pair._id}
								onClick={handlerSelectedPair(pair)}
								className={styles.li}
								data-testid="pair-dropdown-item"
							>
								<Pair pair={pair} />
							</li>
						);
					})}
				</ul>

				<Divider>Application tokens</Divider>

				<ul data-testid="pair-dropdown-list" className={styles.list}>
					{pairs.length === 0 && isLoading && <Loader />}
					{pairs.length > 0 &&
						[...data]
							.sort((x, i) => (x.buy.label > i.buy.label ? 1 : -1))
							.map((pair) => {
								return (
									<li
										key={pair._id}
										onClick={handlerSelectedPair(pair)}
										className={styles.li}
										data-testid="pair-dropdown-item"
									>
										<Pair pair={pair} />
									</li>
								);
							})}
				</ul>
			</InfiniteScroll>
		</div>
	);
};

const Loader = () => <p style={{ margin: "6px 0 0 12px" }}>Loading...</p>;
