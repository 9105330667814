import cn from "classnames";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/edit.svg";
import styles from "./Provider.module.scss";

export interface ProviderProps {
	title: string;
	isError: boolean;
	isActive: boolean;
	onRemoveProvider?: () => void;
	onSelectProvider: () => void;
}

export const Provider = (props: ProviderProps) => {
	const { title, isActive, isError, onSelectProvider: onProviderSelect, onRemoveProvider } = props;

	return (
		<div className={styles.wrapper}>
			<div className={styles.inner}>
				<span
					data-testid="provider-status-wrapper"
					onClick={onProviderSelect}
					className={cn(styles.providerTitle, styles.href, {
						[styles.selected]: isActive,
						[styles.error]: isError,
					})}
				>
					<span data-testid="change-provider" className={styles.value}>
						{title}
					</span>
				</span>
				{onRemoveProvider && (
					<EditIcon className={styles.edit} title="Remove provider" onClick={onRemoveProvider} />
				)}
			</div>
		</div>
	);
};
