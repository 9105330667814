import { CSSProperties, ReactNode } from "react";
import styles from "./Body.module.scss";

type BodyProps = {
	children: ReactNode;
	style?: CSSProperties;
};

const Body = ({ children, style }: BodyProps) => {
	return (
		<div style={style} className={styles.root}>
			{children}
		</div>
	);
};

export default Body;
