const IPFS_GATEWAY = "https://ipfs-gateway.mooo.com";

const PAGE_URLS = {
	CREATE_TOKEN: "create-token",
	EXCHANGE: "exchange",
};

const DEFAULT_NETWORK = "goerli";

const DEFAULT_CHAIN_ID = 5;

const DEFAULT_ECOSYSTEM = "zoo";

// text
const ERROR_MESSAGES = {
	IPFS_UPLOAD_FAIL: "Cannot upload file to IPFS.",
	MINING_ERROR: "Transaction mining failed. Please, try again.",
	PROVIDER_ADDITION_INCORRECT_ADDRESS: "You are trying to enter the wrong address",
	PROVIDER_ADDITION_INCORRECT_ADDRESS_WRONG_NETWORK:
		"This provider does not match the current network",
	TOKEN_ICON_INPUT_OVER_SIZE: "Image dimensions are larger than 128px*128px",
	TOKEN_ICON_INPUT_UNSUPPORTED_EXTENSION: "Only PNG or SVG supported",
};

const DEFAULT_SLIPPAGES = ["0.1", "0.5", "1"];

const DEFAULT_THEME = {
	styles: "/db/zoo/theme/theme.css",
	logo: "/db/zoo/theme/logo.svg",
	favicon: "/db/zoo/theme/favicon.png",
};

export {
	PAGE_URLS,
	DEFAULT_ECOSYSTEM,
	IPFS_GATEWAY,
	DEFAULT_NETWORK,
	DEFAULT_CHAIN_ID,
	ERROR_MESSAGES,
	DEFAULT_SLIPPAGES,
	DEFAULT_THEME,
};
