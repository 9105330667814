import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { DEFAULT_SLIPPAGES } from "../../constants";
import { stripNonNumerics } from "../../helpers/stripNonNumerics";
import { ClickAwayListener } from "../../HOC/ClickAwayListener";
import styles from "./Slippage.module.scss";

interface Props {
	slippage: string;
	onSlippageChange: (slippage: string) => void;
}

export const Slippage: FC<Props> = ({ slippage, onSlippageChange }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [inputValue, setInputValue] = useState(slippage);
	const [isInvalid, setInvalid] = useState(false);

	const onClickAway = () => setIsEditing(false);

	useEffect(() => {
		if (!isEditing) {
			if (Number(inputValue) <= 50) {
				onSlippageChange(inputValue);
			} else {
				setInvalid(true);
			}
		}
	}, [inputValue, isEditing, onSlippageChange]);

	const handleSlippageChange = (value: string) => {
		setInvalid(false);
		const numerics = stripNonNumerics(value);
		if (numerics === null) return;
		setInputValue(numerics);
	};

	return (
		<div className={styles.root}>
			<h6 className={styles.title} data-testid="slippage-header">
				Slippage tolerance, %
			</h6>

			<div className={styles.inner}>
				<ul className={styles.list}>
					{DEFAULT_SLIPPAGES.map((item) => {
						const isActive = item === slippage;

						return (
							<li
								data-testid={`slippage-button${isActive ? "-active" : ""}`}
								key={item}
								onClick={() => handleSlippageChange(item)}
								className={cn(styles.item, styles.hover, "transition", {
									[styles.isActive]: isActive,
								})}
							>
								{item}
							</li>
						);
					})}
				</ul>
				<label
					className={cn(styles.label, styles.item, {
						[styles["invalid-input-error"]]: isInvalid,
					})}
					data-testid={isInvalid ? "invalid-input-error" : undefined}
				>
					<span className={styles.span}>Manual input</span>
					{isEditing ? (
						<ClickAwayListener onClickAway={onClickAway}>
							<input
								name="slippage-manual-input"
								data-testid="manual-slippage-input-with-away-listener"
								type="string"
								className={styles.input}
								step="any"
								maxLength={3}
								onChange={(e) => handleSlippageChange(e.target.value)}
								value={inputValue}
								autoFocus
							/>
						</ClickAwayListener>
					) : (
						<input
							data-testid="manual-slippage-input"
							type="text"
							className={styles.input}
							value={`${Number(inputValue).toFixed(1)}%`}
							onClick={() => setIsEditing(true)}
							readOnly
						/>
					)}
				</label>
			</div>
		</div>
	);
};
