import { useEffect, useState } from "react";
import { getValidationAddress, regExpIndexEthereum } from "../helpers/validationEnsAddress";

export const getCoingeckoSimpleTokenPriceUri = (
	contracts: string,
	quoteId: string,
	platformId: string,
) => {
	return `https://api.coingecko.com/api/v3/simple/token_price/${platformId}?contract_addresses=${contracts}&vs_currencies=${quoteId}`;
};

export const getCoingeckoSimplePriceUri = (coinIds: string, quoteId: string) => {
	return `https://api.coingecko.com/api/v3/simple/price?ids=${coinIds}&vs_currencies=${quoteId}`;
};

export const fetchCoingeckoSimpleTokenPrice =
	(fetchFunction: any) => async (contract: string, quote: string, platform: string) => {
		try {
			const addr = contract.toLowerCase();
			const quoteId = quote.toLowerCase();
			const platformId = platform.toLowerCase();
			const url = getCoingeckoSimpleTokenPriceUri(addr, quoteId, platformId);

			const data = await fetchFunction(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});

			const result = await data.json();

			const price = result[addr]?.[quoteId];

			return price ? price : undefined;
		} catch (_) {
			return undefined;
		}
	};

export const getCoingeckoSimpleTokenPrice = fetchCoingeckoSimpleTokenPrice(
	typeof window !== "undefined" && window.fetch,
);

export const fetchCoingeckoSimplePrice =
	(fetchFunction: any) => async (coin: string, quote: string) => {
		try {
			const coinId = coin.toLowerCase();
			const quoteId = quote.toLowerCase();
			const url = getCoingeckoSimplePriceUri(coinId, quoteId);

			const data = await fetchFunction(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});

			const result = await data.json();

			const price = result[coinId]?.[quoteId];

			return price ? price : undefined;
		} catch (_) {
			return undefined;
		}
	};

export const getCoingeckoSimplePrice = fetchCoingeckoSimplePrice(
	typeof window !== "undefined" && window.fetch,
);

export const useCoingeckoTokenTotalPrice = (
	coinIdOrContractAddress?: string | null,
	amount?: string | null,
	quote = "usd",
	platform = "ethereum",
): string | undefined => {
	const [price, setPrice] = useState<number | undefined>(undefined);
	// const blockNo = useBlockNumber();

	useEffect(() => {
		async function getPrice() {
			if (!coinIdOrContractAddress) return;

			const isValidEthAddress = getValidationAddress(coinIdOrContractAddress, regExpIndexEthereum);

			if (isValidEthAddress) {
				const tokenPrice = await getCoingeckoSimpleTokenPrice(
					coinIdOrContractAddress,
					quote,
					platform,
				);
				setPrice(tokenPrice);
			} else {
				const tokenPrice = await getCoingeckoSimplePrice(coinIdOrContractAddress, quote);
				setPrice(tokenPrice);
			}
		}

		void getPrice();
	}, [coinIdOrContractAddress, quote, platform]); // blockNo

	if (price === undefined || price === null) return undefined;
	if (amount === undefined || amount === null) return undefined;

	const cost = Number(amount) > 0 ? Number(price) * Number(amount) : undefined;

	return cost?.toString();
};
