import { FC, ReactElement } from "react";
import { useEcosystemSummary } from "../../wrappers/tokenFactory";
import { contextDefaultValues, EcosystemContext } from "./EcosystemContext";

export interface Props {
	children: ReactElement;
	network: string;
	ecosystem: string;
}

// EcosystemWrapper is a workaround to make conditional hook
// useEcosystemSummary() must be called after the complete change of the chain id
export const EcosystemWrapper: FC<Props> = ({ children, ecosystem, network }) => {
	const ecosystemSummary = useEcosystemSummary(ecosystem);

	const config = {
		...contextDefaultValues,
		...ecosystemSummary,
		network,
		ecosystem,
	};

	return <EcosystemContext.Provider value={config}>{children}</EcosystemContext.Provider>;
};
