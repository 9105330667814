import { createContext } from "react";
import { DEFAULT_ECOSYSTEM, DEFAULT_NETWORK } from "../../constants";
import { PROVIDERS as DEFAULT_PROVIDERS } from "../../constants/providers";
import { TOKEN_FACTORIES } from "../../constants/tokenFactories";
import { IPairToken, IToken } from "../../types";
import { EcosystemTokens } from "../../wrappers/tokenFactory";

const DEFAULT_SUPPORTED_NETWORKS = Object.keys(TOKEN_FACTORIES);

export interface IProvider {
	url: string;
	title?: string;
}

export type Theme = {
	styles: string;
	logo: string;
	favicon: string;
};

export type Ecosystem = {
	theme: Theme;
	supportedNetworks: string[];
	defaultNetwork: string;
	ethBrokers: { [network: string]: string };
};

export interface FetchProviders {
	[name: string]: IProvider[];
}

export type EcosystemContextState = {
	isLoading: boolean;
	isEcosystemNotFound: boolean;
	network: string;
	ecosystem: string;
	ecosystemConfig: Ecosystem;
	ecosystemTokens: EcosystemTokens;
	providersConfig: FetchProviders;
	ecosystemTokenPairs: IPairToken[];
};

const DEFAULT_TOKEN: IToken = {
	ABI: "BONDED_TOKEN",
	address: "",
	curveAddress: "",
	formatUnits: 18,
	icon: "",
	label: "",
};

export const contextDefaultValues: EcosystemContextState = {
	isLoading: true,
	isEcosystemNotFound: false,
	network: DEFAULT_NETWORK,
	ecosystem: DEFAULT_ECOSYSTEM,
	ecosystemConfig: {
		defaultNetwork: "",
		ethBrokers: {},
		supportedNetworks: DEFAULT_SUPPORTED_NETWORKS,
		theme: {
			styles: "",
			logo: "",
			favicon: "",
		},
	},
	ecosystemTokens: {
		BONDED_TOKEN: DEFAULT_TOKEN,
		COLLATERAL_TOKEN: DEFAULT_TOKEN,
		UNISWAP: DEFAULT_TOKEN,
	},
	providersConfig: DEFAULT_PROVIDERS,
	ecosystemTokenPairs: [],
};

export const EcosystemContext = createContext<EcosystemContextState>(contextDefaultValues);
