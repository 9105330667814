import { useState } from "react";

export const useClickAwayListener = (): {
	isListening: boolean;
	onClickAway: () => void;
	open: () => void;
} => {
	const [isListening, setIsListening] = useState<boolean>(false);

	const open = (): void => setIsListening(true);

	const onClickAway = () => setIsListening(false);

	return { isListening, onClickAway, open };
};
