export const TOKEN_SUPPLY = [
	100, 1000, 10000, 100000, 500000, 750000, 1000000, 1250000, 1500000, 1750000, 2000000, 3000000,
	5000000, 10000000,
];
export const PRICE = [
	1, 1, 1, 1, 1.01, 1.55, 8.5, 56.59, 290.15, 1154.63, 3814.2, 147523.35, 14658523.52,
	7500362389.74,
];
export const MARKET_CAP = [
	100, 1000, 10000, 100000, 507291, 1167467, 8509016, 70740610, 435238924, 2020619509, 7628418985,
	442570060970, 73292617619751, 75003623897486912,
];
