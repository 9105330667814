import { ethers } from "ethers";
import EthBrokerAbi from "../abis/eth-broker.json";
import { useLoadingCall } from "../hooks/useLoadingCall";

export function useTime(ethBrokerAddress?: string): {
	isLoading: boolean;
	value: ethers.BigNumber | null;
	error: Error | undefined;
} {
	const { isLoading, value, error } = useLoadingCall({
		contractAddress: ethBrokerAddress,
		abi: EthBrokerAbi,
		method: "getTime",
		args: [],
	});

	return { isLoading, value: value?.[0], error };
}
