import { ReactComponent as Close } from "../../assets/images/icons/close.svg";
import Card from "../../components/Card";
import Slippage from "../../components/Slippage";
import styles from "./SettingsCard.module.scss";

type SettingsCardProps = {
	slippage: string;
	onSlippageChange: (slippage: string) => void;
	onSettingsToggle: () => void;
};

const SettingsCard = ({ onSettingsToggle, slippage, onSlippageChange }: SettingsCardProps) => {
	return (
		<Card style={{ width: "560px", height: "600px", boxShadow: "none" }} transparent>
			<Card.Header divided subtitle="Transaction settings">
				<Close
					data-testid="settings-transaction-close"
					onClick={() => onSettingsToggle()}
					className={styles.headerIcon}
				/>
			</Card.Header>
			<Card.Body>
				<Slippage slippage={slippage} onSlippageChange={onSlippageChange} />
			</Card.Body>
		</Card>
	);
};

export default SettingsCard;
