import { useEffect, useState } from "react";
import { toFixed } from "../helpers/toFixed";
import { IPairToken } from "../types";
import { useRate } from "../wrappers/bondingCurve";
import { useDebounce } from "./useDebounce";

export function useRateCalculator(selectedPair: IPairToken | null, swapDirection: "buy" | "sell") {
	// ToDo: the code below is a little confused and it need to be refactored

	// The type of the last modified input: "from" or "to"
	// It's used for rate calculation and input rotation
	const [frozenInput, setFrozenInput] = useState<"from" | "to" | null>(null);

	// Double state to prevent an inifinite loop between two related inputs
	const [_fromAmount, _setFromAmount] = useState("");
	const [_toAmount, _setToAmount] = useState("");
	const [fromAmount, setFromAmount] = useState("");
	const [toAmount, setToAmount] = useState("");

	const [medianPrice, setMedianPrice] = useState(0);

	const debouncedFromAmount = useDebounce(_fromAmount, 500);
	const debouncedToAmount = useDebounce(_toAmount, 500);

	const {
		toAmount: calculatedToAmount,
		fromAmount: calculatedFromAmount,
		isLoading,
	} = useRate({
		selectedPair,
		swapDirection,
		fromAmount: frozenInput === "from" ? debouncedFromAmount : undefined,
		toAmount: frozenInput === "to" ? debouncedToAmount : undefined,
	});

	useEffect(() => {
		if (frozenInput !== "to" || isLoading) return;

		if (calculatedFromAmount != null) {
			setFromAmount(toFixed(calculatedFromAmount));
		}

		if (toAmount === "" || toAmount === "0") {
			setFromAmount("");
		}
	}, [calculatedFromAmount, frozenInput, isLoading, toAmount]);

	useEffect(() => {
		if (frozenInput !== "from" || isLoading) return;

		if (calculatedToAmount != null) {
			setToAmount(toFixed(calculatedToAmount));
		}

		if (fromAmount === "" || fromAmount === "0") {
			setToAmount("");
		}
	}, [calculatedToAmount, fromAmount, frozenInput, isLoading]);

	useEffect(() => {
		if (isLoading) return;

		if (calculatedFromAmount != null && calculatedToAmount != null) {
			setMedianPrice(Number(calculatedFromAmount) / Number(calculatedToAmount));
		} else {
			setMedianPrice(0);
		}
	}, [calculatedFromAmount, calculatedToAmount, isLoading]);

	// reset price when switch pairs
	useEffect(() => {
		setMedianPrice(0);
	}, [selectedPair]);

	function publicSetFromAmount(amount: string) {
		if (amount === "" || amount === "0") {
			clearAmounts();
			return;
		}

		setFrozenInput("from");
		setFromAmount(amount);
		_setFromAmount(amount);
	}

	function publicSetToAmount(amount: string) {
		if (amount === "" || amount === "0") {
			clearAmounts();
			return;
		}

		setFrozenInput("to");
		setToAmount(amount);
		_setToAmount(amount);
	}

	function rotateAmounts() {
		if (frozenInput === "from") {
			setFromAmount(toAmount);
			publicSetToAmount(fromAmount);
		} else {
			setToAmount(fromAmount);
			publicSetFromAmount(toAmount);
		}
	}

	function clearAmounts() {
		setFrozenInput(null);
		setFromAmount("");
		_setFromAmount("");
		setToAmount("");
		_setToAmount("");
	}

	return {
		fromAmount,
		toAmount,
		medianPrice,
		setFromAmount: publicSetFromAmount,
		setToAmount: publicSetToAmount,
		rotateAmounts,
		clearAmounts,
		isLoading,
	};
}
