import cn from "classnames";
import { ReactNode } from "react";
import styles from "./Header.module.scss";

type HeaderProps = {
	title?: string;
	subtitle?: string;
	divided?: boolean;
	children?: ReactNode;
};

const Header = ({ children, title, subtitle, divided }: HeaderProps) => {
	return (
		<>
			<div className={cn(styles.root, { [styles.divided]: divided })}>
				<div className={styles.heading}>
					<span className={styles.title}>{title}</span>
					<span className={styles.subtitle}>{subtitle}</span>
				</div>
				<div className={styles.controls}>{children}</div>
			</div>
			{divided && <hr className={styles.divider}></hr>}
		</>
	);
};

export default Header;
