import Body from "./Body";
import Card from "./Card";
import CreateTokenTxModal from "./CreateTokenTxModal";
import Dependecies from "./Dependecies";
import Header from "./Header";

export default Object.assign(Card, {
	Body: Body,
	Header: Header,
	Dependecies: Dependecies,
	Modal: CreateTokenTxModal,
});
