import WalletConnect from "../WalletConnect";
import WalletProfile from "../WalletProfile";

export interface WalletProps {
	account: string | null | undefined;
	network: string;
	onConnected: () => void;
	onDisconnect: () => void;
}

export const Wallet = ({ account, network, onConnected, onDisconnect }: WalletProps) => {
	const isVisibleProfile = network && account && account.length > 0;

	return isVisibleProfile ? (
		<WalletProfile loggedIn={account} network={network} onDisconnect={onDisconnect} />
	) : (
		<WalletConnect data-testid="connect-wallet-button" onClick={onConnected}>
			Connect wallet
		</WalletConnect>
	);
};
