import FORMULA from "../../assets/images/formula.png";
import GRAPH from "../../assets/images/graph.jpg";
import { MARKET_CAP, PRICE, TOKEN_SUPPLY } from "../../constants/tableInfoParameters";
import styles from "./InfoPages.module.scss";

const NATIVE_DECIMALS = 16;

export type InfoTokenProps = {
	label: string;
	decimals?: number;
	chooseTokenPage?: string;
};

export const InfoToken = ({
	label,
	chooseTokenPage,
	decimals = NATIVE_DECIMALS,
}: InfoTokenProps) => {
	return (
		<div className={styles.wrapperCustomScroll}>
			<div className={styles.infoTokenText}>
				On this page you can create your own bonding curve supplied token with the{" "}
				{chooseTokenPage ? chooseTokenPage : label} as the collateral.
			</div>
			<div className={styles.infoTokenText}>
				The formula for this curve is {""}
				<img src={FORMULA}></img>
			</div>
			<div className={styles.infoTokenText}>
				You can see how price of the token will grow depending on it&apos;s issued amount.
			</div>
			<div className={styles.tableInfo}>
				<div className={styles.tableColumn}>
					<span className={styles.tableItem}>Your Token Supply (S)</span>
					{TOKEN_SUPPLY.map((x, i) => (
						<span className={styles.tableItem} key={i}>
							{x}
						</span>
					))}
				</div>
				<div className={styles.tableColumn}>
					<span className={styles.tableItem}>Price, {label} (P)</span>
					{PRICE.map((x, i) => (
						<span key={i} className={styles.tableItem}>
							{_shiftWithDecimals(x, decimals)}
						</span>
					))}
				</div>
				<div className={styles.tableColumn}>
					<span className={styles.tableItem}>Market Cup, {label}</span>
					{MARKET_CAP.map((x, i) => (
						<span className={styles.tableItem} key={i}>
							{x}
						</span>
					))}
				</div>
			</div>
			<div className={styles.infoTokenText}>
				This graph shows the dependence of the price of your current on how many of them are
				produced (the graph does not show the dependence, only shows its direction).
			</div>
			<div className={styles.infoTokenText}>
				<img style={{ width: "100%" }} src={GRAPH} />
			</div>
		</div>
	);
};

function _shiftWithDecimals(value: number, collateralDecimals: number): string {
	const delta = collateralDecimals - NATIVE_DECIMALS;
	const shiftedValue = value / 10 ** delta;
	let precisied = shiftedValue.toPrecision(9);

	if (precisied.includes("e")) {
		const [left, right] = precisied.split("e");
		return `${left.replace(/\.?0+$/, "")} * 10^${right.replace("+", "")}`;
	} else {
		if (!precisied.includes(".")) precisied = precisied + ".0";
		return precisied.replace(/\.?0+$/, "");
	}
}
