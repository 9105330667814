import classNames from "classnames";
import { useState } from "react";
import Button from "../../components/Button";
import Card from "../../components/Card";
import { ChooseCollateralToken } from "../../components/InfoPages/ChoosePagesCollateral";
import {
	CreateYourTokenPageElement,
	HowToCreateTokenElement,
	InfoAboutEcosystemToken,
} from "../../components/InfoPages/InfoPages";
import { YourCollateralToken } from "../../components/InfoPages/YourCollateralToken";
import LIST_TOKENS from "../../constants/tokens-list.json";
import { useEcosystem } from "../../contexts/EcosystemContext";
import { getValidationAddress, regExpIndexEthereum } from "../../helpers/validationEnsAddress";
import { useToken } from "../../wrappers/erc20Token";
import styles from "./InfoCard.module.scss";

const INFO_TITLE = "Create your own token";
const HOW_TO_TITLE = "How to create a token";

let ECOSYSTEM_TOKEN_TITLE = "Ecosystem Token";
let CHOOSE_ECOSYSTEM_TOKEN = "Choose Ecosystem Token";
let YOUR_TOKEN = "Your Ecosystem Token";

enum PagesTitle {
	CREATE_YOUR_OWN_TOKEN_PAGE = 0,
	HOW_TO_CREATE_A_TOKEN = 1,
	INFO_ABOUT_ECOSYSTEM_TOKEN = 2,
	CHOOSE_TOKEN = 3,
	YOUR_TOKEN_COLLATERAL = 4,
}

export type CollateralToken = {
	name: string;
	address: string;
	symbol: string;
	logoURI: string | undefined;
	price?: string | undefined;
	marketCup?: string | undefined;
	traidingVol?: string | undefined;
	formatUnits?: number | undefined;
};

type InfoCardProps = {
	page: any;
	setPage: (x: any) => void;
	setDataCollateralToken?: any;
	setLearnMore: (x: any) => void;
	isLearnMore: boolean;
	isDisabledInfoPage: boolean;
};

const InfoCard = ({
	page,
	setPage,
	setDataCollateralToken,
	setLearnMore,
	isLearnMore,
	isDisabledInfoPage,
}: InfoCardProps) => {
	const [isCopy, setIsCopy] = useState<boolean>(false);
	const { ecosystemTokens } = useEcosystem();
	const [chooseToken, setChooseToken] = useState<string>("");

	const [collateralToken, setCollateralToken] = useState<CollateralToken>({
		name: "",
		address: "",
		symbol: "",
		formatUnits: 18,
		logoURI: undefined,
		price: undefined,
		marketCup: undefined,
		traidingVol: undefined,
	});
	const [isVisibleDropdown, setVisibleDropdown] = useState<boolean>(false);
	const [isVisibleDelete, setVisibleDelete] = useState<boolean>(false);
	const [dataListTokens, setListTokens] = useState(LIST_TOKENS);
	const daiInfo = useToken(
		getValidationAddress(chooseToken, regExpIndexEthereum) !== null
			? chooseToken
			: ecosystemTokens.BONDED_TOKEN.address,
	);
	const cardTitle = () => {
		if (page === PagesTitle.CREATE_YOUR_OWN_TOKEN_PAGE) {
			return INFO_TITLE;
		} else if (page === PagesTitle.HOW_TO_CREATE_A_TOKEN) {
			return HOW_TO_TITLE;
		} else if (page === PagesTitle.INFO_ABOUT_ECOSYSTEM_TOKEN) {
			ECOSYSTEM_TOKEN_TITLE = ecosystemTokens?.BONDED_TOKEN?.label;
			return ECOSYSTEM_TOKEN_TITLE;
		} else if (page === PagesTitle.CHOOSE_TOKEN) {
			if (isVisibleDropdown) {
				CHOOSE_ECOSYSTEM_TOKEN = "Find Collateral Token";
			} else {
				CHOOSE_ECOSYSTEM_TOKEN = "Choose Collateral Token";
			}
			return CHOOSE_ECOSYSTEM_TOKEN;
		} else if (page === PagesTitle.YOUR_TOKEN_COLLATERAL) {
			collateralToken && collateralToken.name.length > 1
				? (YOUR_TOKEN = collateralToken.name)
				: (YOUR_TOKEN = "Your Collateral Token");
			return YOUR_TOKEN;
		}
	};

	const copyAddress = (address: string | null) => () => {
		if (isCopy || !address) return false;

		navigator.clipboard
			.writeText(address)
			.then(copyTimeout)
			.catch((err) => console.log("Something went wrong", err));
	};

	function copyTimeout() {
		setIsCopy(true);
		const timeout = setTimeout(() => {
			setIsCopy(false);
			clearTimeout(timeout);
		}, 500);
	}
	const getInfoPage = (x: boolean) => {
		return () => {
			setLearnMore(x);
		};
	};
	const transitionPage = (x: any) => {
		return () => {
			setPage(x);
		};
	};

	return (
		<Card>
			<Card.Header title={cardTitle()}>
				{isLearnMore && (
					<Button
						className={classNames(styles.btnSave, {
							[styles.btnBack]: true,
						})}
						onClick={getInfoPage(false)}
					>
						Back
					</Button>
				)}
			</Card.Header>
			<Card.Body>
				<div className={styles.infoContent}>
					{page === PagesTitle.CREATE_YOUR_OWN_TOKEN_PAGE && (
						<CreateYourTokenPageElement
							label={ecosystemTokens?.BONDED_TOKEN?.label}
							setPage={transitionPage(PagesTitle.HOW_TO_CREATE_A_TOKEN)}
						/>
					)}
					{page === PagesTitle.HOW_TO_CREATE_A_TOKEN && (
						<HowToCreateTokenElement
							setPage={transitionPage(PagesTitle.CREATE_YOUR_OWN_TOKEN_PAGE)}
						/>
					)}
					{page === PagesTitle.INFO_ABOUT_ECOSYSTEM_TOKEN && (
						<InfoAboutEcosystemToken
							isLearnMore={isLearnMore}
							copyAddress={copyAddress(ecosystemTokens?.BONDED_TOKEN?.address)}
							setLearnMore={setLearnMore}
							setPage={setPage}
						/>
					)}
					{page === PagesTitle.CHOOSE_TOKEN && (
						<ChooseCollateralToken
							isLearnMore={isLearnMore}
							setPage={setPage}
							chooseToken={chooseToken}
							setListTokens={setListTokens}
							isVisibleDropdown={isVisibleDropdown}
							setVisibleDropdown={setVisibleDropdown}
							setChooseToken={setChooseToken}
							setLearnMore={setLearnMore}
							dataListTokens={dataListTokens}
							setCollateralToken={setCollateralToken}
							daiInfo={daiInfo}
						/>
					)}
					{page === PagesTitle.YOUR_TOKEN_COLLATERAL && (
						<YourCollateralToken
							isLearnMore={isLearnMore}
							setPage={setPage}
							setVisibleDropdown={setVisibleDropdown}
							collateralToken={collateralToken}
							setLearnMore={setLearnMore}
							isVisibleDelete={isVisibleDelete}
							setVisibleDelete={setVisibleDelete}
							copyAddress={copyAddress(collateralToken.address)}
							setDataCollateralToken={setDataCollateralToken}
							isDisabledInfoPage={isDisabledInfoPage}
							setChooseToken={setChooseToken}
						/>
					)}
				</div>
			</Card.Body>
		</Card>
	);
};

export default InfoCard;
