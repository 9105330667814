import { useEthers, useTokenAllowance } from "@usedapp/core";
import { IPairToken } from "../types";

export function useAllowance(selectedPair: IPairToken | null, swapDirection: "buy" | "sell") {
	const { buy, sell } = selectedPair ?? {};

	// ToDo: move it into separate function because this logic is needed in several places
	// ToDo: sell Ethereum for Bonded token is ignored
	const isEthBroker = sell?.ABI === "UNISWAP" || buy?.ABI === "UNISWAP";
	const bondingCurve = swapDirection === "buy" ? buy?.curveAddress : sell?.curveAddress;
	const spenderAddress = !isEthBroker
		? bondingCurve
		: buy?.ABI === "UNISWAP"
		? buy?.curveAddress
		: null;

	const tokenAddress = sell?.address;

	const { account: owner } = useEthers();
	const allowance = useTokenAllowance(tokenAddress, owner, spenderAddress);

	return allowance;
}
