import { FC, ReactElement } from "react";
import { Navigate, useParams } from "react-router-dom";
import { NETWORKS_LIST } from "../../constants/networks";
import { getKeyByValue } from "../../helpers/getKeyByValue";
import UnsupportedNetwork from "../../pages/UnsupportedNetwork";

interface Props {
	children: ReactElement;
}

export const NetworkValidator: FC<Props> = ({ children }: Props): ReactElement | null => {
	const { network, ecosystem } = useParams() as { network: string; ecosystem: string };

	// contains uppercase?
	if (network.toLowerCase() !== network) {
		return <Navigate to={`/${network.toLowerCase()}/${ecosystem}`} replace />;
	}

	if (getKeyByValue(NETWORKS_LIST, network.toLowerCase()) === null) {
		return <UnsupportedNetwork />;
	}

	return children;
};
