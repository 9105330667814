import cn from "classnames";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import styles from "./Checkbox.module.scss";

export interface CheckboxProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
	checked?: boolean;
	onChecked?: (value: boolean) => void | undefined;
}

export const Checkbox: FC<CheckboxProps> = ({
	checked = false,
	onChange = () => ({}),
}: CheckboxProps) => {
	return (
		<label data-testid="change-network-auto-checkbox" className={cn(styles.label)}>
			<div className={cn(styles.isCheck, { [styles.checked]: checked })}>
				<input
					data-testid="provider-auto-mode-checkbox"
					type="checkbox"
					checked={checked}
					onChange={onChange}
				/>
				<span />
			</div>
		</label>
	);
};
