import React from "react";
import WalletConnect from "../WalletConnect";

export interface SwapButtonsProps {
	connect: boolean;
	approve: boolean | "" | null;
	exchange: boolean | "" | null;
	isApproveLoading: boolean;
	isExchangeLoading: boolean;
	isDisabled: boolean;
	isInsufficientBalance: boolean;
	onConnectWalletClick: () => void;
	onApproveClick: () => void;
	onExchangeClick: () => void;
	fromAmount: string | undefined;
	toAmount: string | undefined;
}

export const SwapButtons = (props: SwapButtonsProps) => {
	const {
		approve,
		isApproveLoading,
		connect,
		exchange,
		isExchangeLoading,
		isDisabled,
		isInsufficientBalance,
		onApproveClick,
		onExchangeClick,
		onConnectWalletClick,
		fromAmount,
		toAmount,
	} = props;

	const isZeroFrom = !fromAmount || fromAmount === "0";
	const isZeroTo = !toAmount || toAmount === "0";
	const isZeroInputs = isZeroFrom || isZeroTo;

	return (
		<React.Fragment>
			{connect && (
				<WalletConnect data-testid="exchange-connect-wallet-button" onClick={onConnectWalletClick}>
					Connect wallet
				</WalletConnect>
			)}
			{approve && (
				<WalletConnect
					onClick={onApproveClick}
					loading={isApproveLoading}
					disabled={isDisabled}
					data-testid="approve-button"
				>
					{isInsufficientBalance
						? "Insufficient balance"
						: isZeroInputs
						? "Enter an amount"
						: "Approve transaction"}
				</WalletConnect>
			)}
			{exchange && (
				<WalletConnect
					onClick={onExchangeClick}
					loading={isExchangeLoading}
					disabled={isDisabled}
					data-testid="exchange-button"
				>
					{isInsufficientBalance
						? "Insufficient balance"
						: isZeroInputs
						? "Enter an amount"
						: "Exchange transaction"}
				</WalletConnect>
			)}
		</React.Fragment>
	);
};
