import cn from "classnames";
import { ReactComponent as ArrowsMini } from "../../assets/images/icons/arrows-mini.svg";
import { toFixed } from "../../helpers/toFixed";
import { IPairToken } from "../../types";
import { useRate } from "../../wrappers/bondingCurve";
import styles from "./Pairs.module.scss";

export interface PairProps {
	pair: IPairToken | null;
}

export const Pair = ({ pair: pair }: PairProps) => {
	const { fromAmount: price } = useRate({
		selectedPair: pair,
		swapDirection: "buy",
		toAmount: "1",
	});

	return (
		<div className={cn(styles.item, "transition")}>
			<div className={styles.left}>
				<h6 data-testid="pair-text" className={styles.itemTitle}>
					<span>{pair?.buy.label}</span>
					<ArrowsMini className={styles.arrows} />
					<span>{pair?.sell.label}</span>
				</h6>
			</div>

			<div className={styles.right}>
				<span className={styles.price}>{price ? toFixed(price) : "0.0"}</span>
			</div>
		</div>
	);
};
