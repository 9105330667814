import ETH from "../assets/images/logo_tokens/ethereum.png";

const resources = [
	{
		title: "Ethereum",
		type: "token",
		icon: ETH,
	},
];

export { resources };
