import { useEthers, useUpdateConfig } from "@usedapp/core";
import { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_CHAIN_ID } from "../../constants";
import { NETWORKS_LIST } from "../../constants/networks";
import { createProvider } from "../../helpers/createProvider";
import { getKeyByValue } from "../../helpers/getKeyByValue";
import { sendRpcRequest } from "../../helpers/sendRpcRequest";
import { useProvider } from "../../helpers/useProvider";
import { useEcosystem } from "../EcosystemContext";
import { contextDefaultValues, RpcContext, RpcContextState } from "./RpcContext";

const LS_CUSTOM_PROVIDERS = "custom-providers";
const LS_SELECTED_PROVIDER = "selected-provider";

async function checkProviderAvailability(rpcUrl: string): Promise<boolean> {
	try {
		const blockNumberHex = await sendRpcRequest("eth_blockNumber", rpcUrl);
		return parseInt(blockNumberHex) > 0;
	} catch (_) {
		return false;
	}
}

async function checkProviderCompatibility(
	rpcUrl: string,
	expectedChainId: number,
): Promise<boolean> {
	try {
		const receivedChainIdHex = await sendRpcRequest("eth_chainId", rpcUrl);
		return parseInt(receivedChainIdHex) === expectedChainId;
	} catch (_) {
		return false;
	}
}

type Props = {
	children: ReactElement;
};

const RpcProvider: FC<Props> = ({ children }) => {
	const navigate = useNavigate();
	const updateConfig = useUpdateConfig();
	const { chainId: currentChainId } = useProvider();
	const { error: ethersError, activate } = useEthers();
	const { providersConfig, ecosystemConfig, ecosystem, network } = useEcosystem();

	const [isAutoProviderSelection, setAutoProvider] = useState(true);
	const [selectedRpcProvider, setRpcProvider] = useState<string | null>(null);
	const [customProviders, setCustomProviders] = useState<string[]>([]);
	const [error, setError] = useState<Error | undefined>();

	useEffect(() => {
		if (ethersError) {
			setError(ethersError);
		}
	}, [ethersError]);

	// update ethers.js provider
	useEffect(() => {
		if (!currentChainId || !selectedRpcProvider) return;

		setError(undefined);

		const provider = createProvider(selectedRpcProvider, currentChainId);

		const config = {
			readOnlyChainId: currentChainId,
			readOnlyUrls: {
				[currentChainId]: provider,
			},
		};

		updateConfig(config);
	}, [selectedRpcProvider, currentChainId, updateConfig]);

	// update rpc provider if ecosystem was changed
	useEffect(() => {
		if (!currentChainId || !providersConfig[network]) return;

		const savedCustomProviders = JSON.parse(
			localStorage[`${LS_CUSTOM_PROVIDERS}/${network}/${ecosystem}`] ?? "[]",
		);
		const savedRpcUrl = localStorage[`${LS_SELECTED_PROVIDER}/${network}/${ecosystem}`];
		const defaultRpcUrl = providersConfig[network][0].url;

		setRpcProvider(savedRpcUrl ?? defaultRpcUrl);
		setAutoProvider(!savedRpcUrl);
		setCustomProviders(savedCustomProviders);
	}, [ecosystem, network, currentChainId, providersConfig]);

	if (currentChainId === undefined || !NETWORKS_LIST[currentChainId]) {
		return <RpcContext.Provider value={contextDefaultValues}>{children}</RpcContext.Provider>;
	}

	const defaultRpcProviders = providersConfig[NETWORKS_LIST[currentChainId]].map((x, i) => ({
		title: x.title ?? `Default provider ${i + 1}`,
		url: x.url,
		isCustom: false,
	}));

	const customRpcProviders = customProviders.map((url) => ({
		title: url,
		url: url,
		isCustom: true,
	}));

	const rpcProviders = [...defaultRpcProviders, ...customRpcProviders];

	const { supportedNetworks: supportedEcosystemNetworks } = ecosystemConfig;

	const supportedNetworks = supportedEcosystemNetworks.map((x) => {
		const _chainId = getKeyByValue(NETWORKS_LIST, x) ?? DEFAULT_CHAIN_ID;
		return {
			title: x,
			chainId: _chainId,
			isUnderDevelopment: !supportedEcosystemNetworks.includes(x),
		};
	});

	async function addCustomRpcProvider(rpcUrl: string) {
		if (!currentChainId) return;

		if (rpcProviders.find((x) => x.url === rpcUrl)) {
			throw new Error("This provider already exists.");
		}

		if (!(await checkProviderAvailability(rpcUrl))) {
			throw new Error("The provider does not work");
		}

		if (!(await checkProviderCompatibility(rpcUrl, currentChainId))) {
			throw new Error("This provider does not match the current network.");
		}

		const newCustomProviders = [...customProviders, rpcUrl];

		// save providers to local storage
		localStorage[`${LS_CUSTOM_PROVIDERS}/${network}/${ecosystem}`] =
			JSON.stringify(newCustomProviders);

		setCustomProviders(newCustomProviders);
		selectRpcProvider(rpcUrl);
	}

	function removeCustomRpcProvider(rpcUrl: string) {
		const newCustomProviders = [...customProviders].filter((x) => x !== rpcUrl);

		// removed selected provider
		if (selectedRpcProvider === rpcUrl) {
			selectRpcProvider(rpcProviders[0].url);
		}

		// save providers to local storage
		localStorage[`${LS_CUSTOM_PROVIDERS}/${network}/${ecosystem}`] =
			JSON.stringify(newCustomProviders);

		setCustomProviders(newCustomProviders);
	}

	function selectRpcProvider(rpcUrl: string) {
		// save selected provider to local storage
		localStorage[`${LS_SELECTED_PROVIDER}/${network}/${ecosystem}`] = rpcUrl;
		setRpcProvider(rpcUrl);
	}

	function switchNetwork(chainId: number) {
		const network = NETWORKS_LIST[chainId];
		navigate(`/${network}/${ecosystem}`);
	}

	function setAutoProviderSelection(isAuto: boolean) {
		if (isAuto) {
			delete localStorage[`${LS_SELECTED_PROVIDER}/${network}/${ecosystem}`];
		} else {
			localStorage[`${LS_SELECTED_PROVIDER}/${network}/${ecosystem}`] = selectedRpcProvider;
		}

		setAutoProvider(isAuto);
	}

	function resetError() {
		setError(undefined);
	}

	const config: RpcContextState = {
		supportedNetworks,
		rpcProviders,
		currentChainId,
		selectedRpcProvider,
		isAutoProviderSelection,
		setAutoProviderSelection,
		selectRpcProvider,
		addCustomRpcProvider,
		removeCustomRpcProvider,
		switchNetwork,
		error,
		resetError,
	};

	return <RpcContext.Provider value={config}>{children}</RpcContext.Provider>;
};

export { RpcProvider };
