import cn from "classnames";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import "./StepProgressBar.scss";

export const StepProgressBar = ({ percent = 0 }) => {
	return (
		<ProgressBar percent={percent}>
			<Step>
				{({ accomplished, index }: any) => (
					<div
						className={cn("indexedStep", {
							["accomplished"]: accomplished,
						})}
					>
						{index}
					</div>
				)}
			</Step>
			<Step>
				{({ accomplished, index }: any) => (
					<div
						className={cn("indexedStep", {
							["accomplished"]: accomplished,
						})}
					>
						{index}
					</div>
				)}
			</Step>
			<Step>
				{({ accomplished, index }: any) => (
					<div
						className={cn("indexedStep", {
							["accomplished"]: accomplished,
						})}
					>
						{index}
					</div>
				)}
			</Step>
		</ProgressBar>
	);
};
