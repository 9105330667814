import { FC } from "react";
import LegacyButton from "../LegacyButton";
import styles from "./Error.module.scss";

export interface ErrorProps {
	title: string;
	subtitle: string;
	onDisconnect?: () => void;
	loggedIn?: string | null;
}

export const Error: FC<ErrorProps> = (props: ErrorProps) => {
	const { title, subtitle, onDisconnect, loggedIn } = props;
	return (
		<div className={styles.wrapper}>
			<div className={styles.bg} />
			<div className={styles.inner}>
				<h2 className={styles.title}>{title}</h2>
				<p className={styles.subtitle}>{subtitle}</p>
				{loggedIn && loggedIn.length > 0 && onDisconnect && (
					<LegacyButton appearance="provider" onClick={onDisconnect}>
						Disconnect
					</LegacyButton>
				)}
			</div>
		</div>
	);
};
