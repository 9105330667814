import {
	Call,
	ERC20Interface,
	Falsy,
	useCalls,
	useContractFunction,
	useNotifications,
} from "@usedapp/core";
import { BigNumber, Contract } from "ethers";
import { useEffect } from "react";

export function useTokenApprove(tokenAddress: string | Falsy) {
	const contract = tokenAddress ? new Contract(tokenAddress, ERC20Interface) : undefined;
	const { state, send, resetState } = useContractFunction(contract, "approve", {
		transactionName: "Approve",
	});

	const { addNotification } = useNotifications();

	useEffect(() => {
		if (state.status === "Exception") {
			addNotification({
				chainId: state.chainId,
				notification: {
					type: "transactionRejected",
					submittedAt: Date.now(),
					...state,
				},
			} as any);
		}
	}, [state]);

	function approve(spenderAddress: string, allowanceAmount: BigNumber) {
		send(spenderAddress, allowanceAmount);
	}

	return {
		approve,
		status: state,
		resetState,
	};
}
export function useToken(tokenAddress: string | Falsy) {
	const partialCall = tokenAddress && {
		contract: new Contract(tokenAddress, ERC20Interface),
		address: tokenAddress,
		args: [],
	};

	const args = ["name", "symbol", "decimals", "totalSupply"].map(
		(method): Call | Falsy => partialCall && { ...partialCall, method },
	);
	const [name, symbol, decimals, totalSupply] = useCalls(args);

	if (!name || !symbol || !decimals || !totalSupply) {
		return undefined;
	}
	if (!name?.value) {
		return undefined;
	}

	return {
		name: name?.value[0] ?? "",
		symbol: symbol?.value[0] ?? "",
		decimals: decimals?.value[0],
		totalSupply: totalSupply?.value[0],
	};
}
