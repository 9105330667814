import { ReactComponent as Add } from "../../assets/images/icons/add.svg";
import Card from "../../components/Card";
import { useEcosystem } from "../../contexts/EcosystemContext/useEcosystem";
import styles from "./CreateCard.module.scss";
import { CollateralToken } from "./InfoCard";
import TokenForm from "./TokenForm";

const CREATE_HEADER = "Custom token creation";

type CreateCardProps = {
	setPage: (x: any) => void;
	dataCollateralToken?: CollateralToken[];
	setLearnMore: any;
	setDisabledInfoPage: (x: any) => void;
	isDisabledInfoPage: boolean;
};

const CreateCard = ({
	setPage,
	dataCollateralToken,
	setLearnMore,
	setDisabledInfoPage,
	isDisabledInfoPage,
}: CreateCardProps) => {
	const { ecosystemTokens } = useEcosystem();

	const labelEcosystemTokens = ecosystemTokens.BONDED_TOKEN;
	const transitionPage = (x: boolean, i: number) => {
		return () => {
			setPage(i);
			setLearnMore(x);
		};
	};
	return (
		<Card>
			<span className={styles.cardHeaderWrapper}>
				<Card.Header subtitle={CREATE_HEADER}></Card.Header>
			</span>
			<Card.Dependecies>
				<span
					data-testid="label-ecosystem-token"
					className={styles.ecosystemTokenName}
					onClick={transitionPage(false, 2)}
				>
					{labelEcosystemTokens.label}
				</span>
				{dataCollateralToken &&
					dataCollateralToken.map((x, i) => {
						return (
							<span
								style={{ marginLeft: "19px", textTransform: "uppercase" }}
								key={i}
								className={styles.ecosystemTokenName}
								onClick={transitionPage(false, 4)}
							>
								{x.symbol}
							</span>
						);
					})}
				{!dataCollateralToken || dataCollateralToken.length === 0 ? (
					<button
						disabled={isDisabledInfoPage}
						data-testid="add-collateral-token"
						className={styles.addToken}
						onClick={transitionPage(false, 3)}
					>
						<Add />
					</button>
				) : null}
			</Card.Dependecies>
			<Card.Body>
				<TokenForm
					dataCollateralToken={dataCollateralToken}
					setDisabledInfoPage={setDisabledInfoPage}
				></TokenForm>
			</Card.Body>
		</Card>
	);
};

export default CreateCard;
