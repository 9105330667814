import cn from "classnames";
import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, ReactElement } from "react";
import { stripNonNumerics } from "../../helpers/stripNonNumerics";
import { toFixed, toPrecision } from "../../helpers/toFixed";
import { IToken } from "../../types";
import styles from "./Input.module.scss";

export interface InputProps
	extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	behavior: "From" | "To (estimated)";
	isMax?: boolean;
	name?: string;
	token?: IToken | null;
	usdPrice?: string | null;
	balance: string | null;
	amount: string;
	onChangeAmount: (amount: string) => void;
	onMaxButtonClick?: () => void;
}

export const Input = (props: InputProps): ReactElement => {
	const {
		behavior,
		isMax = false,
		style,
		token,
		disabled,
		usdPrice,
		balance,
		amount,
		onChangeAmount,
		onMaxButtonClick,
		...otherProps
	} = props;

	function handleMaxClick() {
		onMaxButtonClick?.();
	}

	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const numerics = stripNonNumerics(event.target.value);
		if (numerics === null) return;
		onChangeAmount(numerics);
	}

	// ToDo: get rid of the hardcode
	const tokenIconUrl = token?.icon.toString().includes("assets.coingecko.com")
		? token?.icon.toString().replace("ipfs-gateway.mooo.com/ipfs/", "")
		: token?.icon;

	return (
		<div data-testid="input-container" style={style}>
			<h5 className={cn(styles.text, styles.behavior)}>{behavior}</h5>

			<div
				className={cn(styles.wrapper, {
					[styles.disabled]: disabled,
				})}
			>
				<label className={styles.label}>
					<div className={styles.left}>
						<div
							className={styles.icon}
							style={{
								backgroundImage: tokenIconUrl ? `url(${tokenIconUrl})` : undefined,
							}}
						></div>

						<div className={styles.description}>
							<span className={styles.name} data-testid="token-exchange-from-input-label">
								{token?.label}
							</span>
							<span className={styles.text} title={balance ? toFixed(balance) : ""}>
								Balance:
								<span data-testid="current-balance">{!balance ? "n/a" : toFixed(balance)}</span>
							</span>
						</div>
					</div>

					<div className={styles.right}>
						<input
							{...otherProps}
							type="text"
							className={styles.input}
							placeholder="0"
							value={amount}
							onChange={handleInputChange}
							disabled={disabled}
						/>

						<span className={cn(styles.text, styles.converted)}>
							{usdPrice !== undefined ? <>~${toPrecision(usdPrice ?? "0")}</> : null}
						</span>
					</div>
				</label>

				{isMax && !disabled && balance && +balance !== 0 && (
					<button className={styles.max} onClick={handleMaxClick} data-testid="max-amount-button">
						max
					</button>
				)}
			</div>
		</div>
	);
};
