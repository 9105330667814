import Error from "../../components/Error";
import { NETWORKS_LIST } from "../../constants/networks";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";

export default function EcosystemNotFound() {
	const supportedNetworks = Object.values(NETWORKS_LIST).map(capitalizeFirstLetter);

	return (
		<Error
			title="No such ecosystem exists"
			subtitle={`Please specify the name of the ecosystem or switch to another network (${supportedNetworks.join(
				", ",
			)})`}
		/>
	);
}
