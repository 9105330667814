import { CSSProperties, ReactNode } from "react";
import styles from "./Dependecies.module.scss";

type DependeciesProps = {
	children: ReactNode;
	style?: CSSProperties;
};

const Dependecies = ({ children, style }: DependeciesProps) => {
	return (
		<div style={style} className={styles.root}>
			<span className={styles.dependeciesTitle}>Dependecies:</span>
			{children}
		</div>
	);
};

export default Dependecies;
