import { useChainMeta } from "@usedapp/core";
import classNames from "classnames";
import FORMULA from "../../assets/images/formula.png";
import { ReactComponent as DeleteToken } from "../../assets/images/icons/delete_token.svg";
import { ReactComponent as DropdownArrow } from "../../assets/images/icons/dropdown-arrow.svg";
import LIST_TOKENS from "../../constants/tokens-list.json";
import { useEcosystem } from "../../contexts/EcosystemContext";
import { useProvider } from "../../helpers/useProvider";
import { getValidationAddress, regExpIndexEthereum } from "../../helpers/validationEnsAddress";
import { useCoingeckoCoinInfo } from "../../hooks/useCoingeckoCoinInfo";
import Button from "../Button";
import styles from "./InfoPages.module.scss";
import { InfoToken } from "./InfoToken";

export type ChooseCollateralTokenProps = {
	isLearnMore: boolean;
	setPage: (x: any) => void;
	chooseToken: string;
	setListTokens: (x: any) => void;
	isVisibleDropdown: boolean;
	setVisibleDropdown: (x: any) => void;
	setChooseToken: (x: any) => void;
	setLearnMore: (x: any) => void;
	dataListTokens: any;
	setCollateralToken: (x: any) => void;
	daiInfo: any;
};

export const ChooseCollateralToken = ({
	isLearnMore,
	setPage,
	chooseToken,
	setListTokens,
	isVisibleDropdown,
	setVisibleDropdown,
	setChooseToken,
	setLearnMore,
	dataListTokens,
	setCollateralToken,
	daiInfo,
}: ChooseCollateralTokenProps) => {
	const { network } = useEcosystem();
	const wethPrice = useCoingeckoCoinInfo(
		getValidationAddress(chooseToken, regExpIndexEthereum) ? chooseToken : null,
		"usd",
	);
	const { chainId } = useProvider();
	const chain = useChainMeta(chainId ?? 1); // ToDo: use DEFAULT_CHAIN_ID

	const getInfoPage = () => {
		setLearnMore(true);
	};

	const getCollateralToken = (x: any) => {
		return () => {
			setCollateralToken({
				name: x.name,
				address: x.address
					? x.address
					: getValidationAddress(chooseToken, regExpIndexEthereum) !== null && chooseToken,
				symbol: x.symbol,
				logoURI: x.logoURI
					? x.logoURI
					: (wethPrice && wethPrice.img_thumb ? wethPrice.img_thumb : undefined) || undefined,
				formatUnits: x.decimals,
			});
			setPage(4);
		};
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setChooseToken("");
		setVisibleDropdown(true);
		setChooseToken(newValue);
		onChange(newValue);
	};

	const getBackChoose = () => {
		setChooseToken("");
		setListTokens(LIST_TOKENS);
		setVisibleDropdown(false);
		setPage(3);
	};

	const transitionEtherscan = (address: string) => {
		return (event: React.MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			window.open(chain.getExplorerAddressLink(address), "_blank");
		};
	};

	const onChange = (value: string) => {
		if (value.length > 0) {
			const filteredListTokens = LIST_TOKENS.filter(
				(x) =>
					x.address.toLowerCase().indexOf(value.toLowerCase()) === 0 ||
					x.name.toLowerCase().includes(value.toLowerCase()) ||
					x.symbol.toLowerCase().includes(value.toLowerCase()),
			);
			setListTokens(filteredListTokens);

			if (getValidationAddress(value, regExpIndexEthereum) !== null) {
				return daiInfo;
			}
		} else {
			setListTokens(LIST_TOKENS);
		}
	};

	const getListTokens = (x: boolean) => {
		return () => {
			setVisibleDropdown(!x);
		};
	};

	const transitionPage = (x: number) => {
		return () => {
			setPage(x);
		};
	};

	const onFocusInput = () => {
		setVisibleDropdown(true);
	};

	const clearInput = (x: number) => {
		return () => {
			setChooseToken("");
			setListTokens(LIST_TOKENS);
			setPage(x);
		};
	};

	return (
		<>
			{!isLearnMore ? (
				<>
					<form
						className={classNames(styles.labelDropdownSearch, {
							[styles.arrowDown]: isVisibleDropdown,
						})}
					>
						<input
							data-testid="input-choice-collateral-token"
							spellCheck="false"
							className={styles.inputDropdownSearch}
							type="text"
							value={chooseToken ? chooseToken : ""}
							placeholder="Type name or address"
							onChange={handleInputChange}
							onFocus={onFocusInput}
						/>
						{chooseToken.length > 0 ? (
							<DeleteToken onClick={clearInput(3)} />
						) : (
							<DropdownArrow onClick={getListTokens(isVisibleDropdown)} />
						)}
					</form>

					{!isVisibleDropdown ? (
						<>
							<div className={styles.infoTokenText}>
								On this page you can create your own bonding curve supplied token with the token you
								need to select in the dropdown as the collateral.
							</div>
							<div className={styles.infoTokenText}>
								The formula for this curve is <img src={FORMULA}></img>{" "}
							</div>
							<Button onClick={getInfoPage} link>
								learn more
							</Button>
						</>
					) : (
						<div className={styles.tokenDropdown} data-testid="is-open-dropdown-collateral-token">
							<ul className={styles.tokenList}>
								{dataListTokens.length > 0 ? (
									dataListTokens
										.filter((x: any) => {
											if (network === "goerli") return x.chainId === 5;
											if (network === "sepolia") return x.chainId === 11155111;
											if (network === "ethereum") return x.chainId === 1;
										})
										.map((x: any, i: any) => (
											<li key={i} onClick={getCollateralToken(x)} className={styles.tokenListItem}>
												<span
													data-testid="change-collateral-token"
													className={styles.tokenListTitle}
												>
													{x.name}
												</span>
												<span className={styles.tokenlistId}>{x.symbol}</span>
												<span
													onClick={transitionEtherscan(x.address)}
													className={styles.tokenlistAddress}
												>
													{x.address}
												</span>
											</li>
										))
								) : (
									<>
										{daiInfo && daiInfo.name !== "Zoo ecosystem" && daiInfo.name !== "BZZ" ? (
											<li onClick={getCollateralToken(daiInfo)} className={styles.tokenListItem}>
												<span
													data-testid="change-collateral-token"
													className={styles.tokenListTitle}
												>
													{daiInfo.name}
												</span>
												<span className={styles.tokenlistId}>{daiInfo.symbol}</span>
												<span
													onClick={transitionEtherscan(chooseToken)}
													className={styles.tokenlistAddress}
												>
													{chooseToken}
												</span>
											</li>
										) : (
											<div
												style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
												className={styles.infoTokenText}
											>
												NO AVAILIBLE TOKEN
											</div>
										)}
									</>
								)}
							</ul>
						</div>
					)}
					{!isVisibleDropdown ? (
						<Button
							data-testid="save-collateral-token"
							className={styles.btnSave}
							onClick={transitionPage(0)}
							disabled
						>
							SAVE
						</Button>
					) : (
						<Button
							className={classNames(styles.btnSave, {
								[styles.btnBack]: true,
							})}
							onClick={getBackChoose}
						>
							Back
						</Button>
					)}
				</>
			) : (
				<InfoToken
					label="Coll.Token"
					chooseTokenPage="the token you need to select in the dropdown"
				/>
			)}
		</>
	);
};
