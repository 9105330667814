export const PROVIDERS = {
	goerli: [
		{
			title: "Goerli Infura 1",
			url: "https://goerli.infura.io/v3/9ded73debfaf4834ac186320de4f85fd",
		},
		{
			title: "Goerli Infura 2",
			url: "https://goerli.infura.io/v3/6b34a47d1ef24f5b9cfff55d32685ad9",
		},
		{
			title: "RPC Goerli",
			url: "https://rpc.goerli.mudit.blog",
		},
		{
			title: "Error Goerli",
			url: "https://3213123321321323123",
		},
	],
	sepolia: [
		{
			title: "Sepolia Infura 1",
			url: "https://sepolia.infura.io/v3/9ded73debfaf4834ac186320de4f85fd",
		},
		{
			title: "Sepolia Infura 2",
			url: "https://sepolia.infura.io/v3/6b34a47d1ef24f5b9cfff55d32685ad9",
		},
	],
	ethereum: [
		{
			title: "Mainnet Infura 1",
			url: "https://mainnet.infura.io/v3/f534378def7a45449b12997d7e77a713",
		},
		{
			title: "Mainnet Infura 2",
			url: "https://mainnet.infura.io/v3/ebf2ebe49b9944f5968610d1d8b9ef9d",
		},
		{
			title: "Mainnet Infura 3",
			url: "https://mainnet.infura.io/v3/a76abcd751ea42908114973709c3e9a6",
		},
	],
};
