import { useConfig, useConnector } from "@usedapp/core";
import { useEffect, useState } from "react";

export function useProvider(): {
	chainId: number | undefined;
} {
	const { connector } = useConnector();

	const { readOnlyChainId } = useConfig();
	const [chainId, setChainId] = useState<number | undefined>();

	useEffect(() => {
		setChainId(connector?.chainId);

		if (!connector) {
			return;
		}

		return connector.updated.on(({ chainId }) => {
			setChainId(chainId);
		});
	}, [connector]);

	return { chainId: chainId ?? readOnlyChainId };
}
