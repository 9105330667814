import { Config, useEthers, useUpdateConfig } from "@usedapp/core";
import { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../components/Shared";
import { DEFAULT_CHAIN_ID, DEFAULT_ECOSYSTEM, DEFAULT_NETWORK } from "../../constants";
import { NETWORKS_LIST } from "../../constants/networks";
import { PROVIDERS } from "../../constants/providers";
import { createProvider } from "../../helpers/createProvider";
import { getKeyByValue } from "../../helpers/getKeyByValue";
import { useProvider } from "../../helpers/useProvider";
import UnsupportedNetwork from "../../pages/UnsupportedNetwork";
import { EcosystemWrapper } from "./EcosystemWrapper";

const LS_SELECTED_PROVIDER = "selected-provider";

type ProviderPorps = {
	children: ReactElement;
};

const EcosystemProvider: FC<ProviderPorps> = ({ children }) => {
	const { network: _network, ecosystem: _ecosystem } = useParams();
	const { account, activate, switchNetwork, deactivate } = useEthers();
	const { chainId: providerChainId } = useProvider();
	const updateConfig = useUpdateConfig();
	const navigate = useNavigate();
	// const isPageVisible = usePageVisibility();

	const [oldChainId, setOldChainId] = useState<number | null>(null);
	const [isSwitchingNetwork, setSwitchingNetwork] = useState(false);

	const network = _network ?? DEFAULT_NETWORK;
	const urlChainId = getKeyByValue(NETWORKS_LIST, network) ?? DEFAULT_CHAIN_ID; // from url
	const ecosystem = _ecosystem ?? DEFAULT_ECOSYSTEM;

	const isNetworkSupported = providerChainId ? NETWORKS_LIST[providerChainId] !== undefined : true;

	// Update read-only provider when URL is changed and wallet is not connected
	useEffect(() => {
		let newChainId = oldChainId ?? DEFAULT_CHAIN_ID;
		if (oldChainId !== urlChainId) newChainId = urlChainId ?? DEFAULT_CHAIN_ID;
		if (oldChainId !== providerChainId) newChainId = providerChainId ?? DEFAULT_CHAIN_ID;

		if (newChainId === oldChainId) return;

		setOldChainId(newChainId);

		if (!account) {
			const network = NETWORKS_LIST[newChainId];
			const savedRpcUrl = localStorage[`${LS_SELECTED_PROVIDER}/${network}/${ecosystem}`];
			const defaultRpcUrl = (PROVIDERS as any)[network]?.[0].url;
			const provider = createProvider(savedRpcUrl ?? defaultRpcUrl, newChainId);
			const newConfig: Config = {
				readOnlyChainId: newChainId,
				readOnlyUrls: {
					[newChainId]: provider,
				},
			};
			updateConfig(newConfig);

			if (providerChainId !== newChainId) {
				activate(provider);
			}
		} else {
			if (!isNetworkSupported) return;
			navigate(`/${NETWORKS_LIST[newChainId ?? DEFAULT_CHAIN_ID] ?? DEFAULT_NETWORK}/${ecosystem}`);

			setSwitchingNetwork(true);
			switchNetwork(newChainId)
				.catch(() => {
					navigate(
						`/${NETWORKS_LIST[oldChainId ?? DEFAULT_CHAIN_ID] ?? DEFAULT_NETWORK}/${ecosystem}`,
					);
				})
				.finally(() => {
					setSwitchingNetwork(false);
				});
		}
	}, [providerChainId, urlChainId]);

	if (!isNetworkSupported) {
		return <UnsupportedNetwork account={account} onDisconnect={deactivate} />;
	}

	// EcosystemWrapper is a workaround to make conditional hook
	// useEcosystemSummary() must be called after the complete change of the chain id
	return providerChainId === urlChainId ? (
		<EcosystemWrapper ecosystem={ecosystem} network={network}>
			{children}
		</EcosystemWrapper>
	) : (
		<Loader message={isSwitchingNetwork ? "Please switch network in MetaMask" : null} />
	);
};

export { EcosystemProvider };
